import * as React from 'react';
import { WithTranslation } from '../../../../lang/WithTranslation.hoc.jsx';
import './MessageTextArea.scss';

type MessageTextAreaProps = JSX.IntrinsicAttributes &
  React.ClassAttributes<HTMLTextAreaElement> &
  React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    placeholder: string;
    isTouched?: boolean;
    error?: string;
  };

const MessageTextArea = ({
  isTouched,
  error,
  placeholder,
  ...textAreaProps
}: MessageTextAreaProps) => (
  <div className="message-text-area-container">
    <div className="input-container">
      <textarea
        maxLength={40}
        id="mydoh-text-area"
        className={error ? 'message-text-area message-text-area--error ' : 'message-text-area'}
        placeholder={placeholder}
        {...textAreaProps}
      ></textarea>
      <label htmlFor="mydoh-text-area" className="label label--message-area">
        {placeholder}
      </label>
    </div>
    <p className="message-text-area-error-message">{error ? error : null}</p>
  </div>
);

export default WithTranslation(MessageTextArea);
