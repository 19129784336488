import React from 'react';
import queryString from 'query-string';
import i18n from '.';

/*
  We need to show localized copy based on the user's language preferences
  stored on the backend. 

  URLs coming from the backend will include a lang query parameter

  So let's grab that from the URL and set i18n with it. 'en' will be the default.

  This takes a moment, so let's delay rendering the wrapped component until 
  the language is set.
*/

export function WithTranslation(InputComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isLanguageSet: false,
      };
    }
    componentDidMount() {
      const lang =
        queryString.parse(this.props?.location?.search)?.lang ||
        queryString.parse(window?.location?.search)?.lang ||
        navigator?.language;
      i18n.changeLanguage(lang || 'fr');
      this.setState({
        isLanguageSet: true,
      });
    }

    render() {
      return this.state.isLanguageSet ? <InputComponent {...this.props} /> : null;
    }
  };
}
