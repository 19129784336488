import { tokenConsumedError, defaultError } from '../../constants/general';
import mydohLogo from '../../assets/images/mydohLogoHorizontal.svg';
const handleBackToApp = () => {
  const { REACT_APP_PREFIX } = process.env;
  window.location.href = `${REACT_APP_PREFIX}signin`;
};

const activationErrors = {
  DEFAULT: defaultError,
  TOKEN_ALREADY_CONSUMED: tokenConsumedError,
};

export default {
  logo: {
    source: mydohLogo,
  },
  success: {
    title: 'Thank You',
    message: 'Your Mydoh account is now active.',
    message2: 'Please log in to the app to continue.',
  },
  mobileBtn: {
    text: 'Return to App',
    onClick: handleBackToApp,
  },
  errors: activationErrors,
};
