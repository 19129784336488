export const defaultError = {
  isError: true,
  title: 'Uh oh!',
  message:
    'Looks like your link has expired. Please resend the link to proceed with account activation.',
};

export const tokenConsumedError = {
  isError: true,
  title: 'Account Activated',
  message:
    'It looks like you’ve already activated your account. Log in to the mobile app to start using Mydoh!',
};

export const FRENCH_SUPPORT_EMAIL = 'bonjour@mydoh.ca';
export const ENGLISH_SUPPORT_EMAIL = 'hello@mydoh.ca';
