import * as Yup from 'yup';
import i18next from '../../lang';

// NOTE: Cardholder name is allowed to have accented characters. Refer to moneris documentation: https://developer.moneris.com/livedemo/3ds2/building/guide/dotnet

export const MonerisInputValidationSchema = Yup.object({
  cardholderName: Yup.string()
    .trim()
    .required(`${i18next.t('monerisContent:formValidation.required')}`)
    .min(2, `${i18next.t('monerisContent:formValidation.minimumCharacters', { minLength: 2 })}`)
    .max(40, `${i18next.t('monerisContent:formValidation.maximumCharacters', { maxLength: 40 })}`)
    .matches(
      /^([a-zA-Z])(([a-zA-Z])+([- ])?)*([a-zA-Z])$/,
      `${i18next.t('monerisContent:formValidation.invalidCharacter')}`,
    ),
  address: Yup.string()
    .required(`${i18next.t('monerisContent:formValidation.required')}`)
    .min(2, `${i18next.t('monerisContent:formValidation.minimumCharacters', { minLength: 2 })}`)
    .max(40, `${i18next.t('monerisContent:formValidation.maximumCharacters', { maxLength: 40 })}`)
    .matches(
      /^([\w',-\\/.\s]*)$/,
      `${i18next.t('monerisContent:formValidation.invalidCharacter')}`,
    ),
  city: Yup.string()
    .required(`${i18next.t('monerisContent:formValidation.required')}`)
    .min(2, `${i18next.t('monerisContent:formValidation.minimumCharacters', { minLength: 2 })}`)
    .max(20, `${i18next.t('monerisContent:formValidation.maximumCharacters', { maxLength: 20 })}`)
    .matches(
      /^([\w',-\\/.\s]*)$/,
      `${i18next.t('monerisContent:formValidation.invalidCharacter')}`,
    ),
  province: Yup.string()
    .required(`${i18next.t('monerisContent:formValidation.required')}`)
    .min(2, `${i18next.t('monerisContent:formValidation.minimumCharacters', { minLength: 2 })}`)
    .max(2, `${i18next.t('monerisContent:formValidation.maximumCharacters', { maxLength: 2 })}`)
    .matches(/^([a-zA-Z]*)$/, `${i18next.t('monerisContent:formValidation.invalidCharacter')}`),
  postalCode: Yup.string()
    .required(`${i18next.t('monerisContent:formValidation.required')}`)
    .min(6, `${i18next.t('monerisContent:formValidation.minimumCharacters', { minLength: 6 })}`)
    .max(7, `${i18next.t('monerisContent:formValidation.maximumCharacters', { maxLength: 7 })}`)
    .matches(/^([a-zA-Z \d]+)$/, `${i18next.t('monerisContent:formValidation.invalidCharacter')}`),
});
