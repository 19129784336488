import React from 'react';
import './Error.scss';

const ErrorMessage = props => {
  return (
    <div className="error-container">
      <span className="error-message">{props.message}</span>
    </div>
  );
};

export default ErrorMessage;
