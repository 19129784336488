export default {
  title: 'Créer un nouveau mot de passe',
  passwordLabel: 'Nouveau mot de passe',
  confirmPasswordLabel: 'Entrer à nouveau le nouveau mot de passe',
  placeholder: 'Entrer le mot de passe ici',
  button: 'Créer un nouveau mot de passe',
  errorTitle: 'Oh-oh',
  errorMsg: "Il semble y avoir un problème. Nous n'avons pu changer votre mot de passe.",
  errorBtn: 'Essayer de nouveau',
  successTitle: 'Succès',
  successMsg:
    "Vous avez modifier votre mot de passe. Veuillez vous connecter à l'appli pour continuer.",
  successBtn: 'Se connecter à Mydoh',
  validatorTitle: 'Les mots de passe doivent contenir',
  minChar: ' au moins 8 caractères',
  capChar: '1 lettre majuscule',
  numberChar: '1 chiffre',
  specialChar: '1 caractère spéciale',
  tooltip: 'caractères valides :',
  complexityError: 'Le mot de passe doit répondre aux critères de complexité',
  matchingError:
    'Les mots de passe ne sont pas les mêmes. Veuillez vérifier et entrer le mot de passe à nouveau',
  tooltipAltText: 'Toucher ici pour obtenir les caractères spéciaux utilisables ',
  linkExpiredTitle: 'RÉESSAYEZ DE RÉINITIALISER VOTRE MOT DE PASSE',
  linkExpiredMessage:
    "Votre lien de réinitialisation du mot de passe a expiré ou a déjà été utilisé. Veuillez retourner dans l'appli pour soumettre une nouvelle demande.",
  linkExpiredButton: `Ouvrir l'appli`,
};
