import React from 'react';
import { MobileView } from 'react-device-detect';
import './Card.scss';

const Card = props => {
  return (
    <div className={'card'}>
      <img
        src={props.image.source}
        alt={'icon'}
        className={`image ${props.image.className}`}
        width={props.image.width}
        height={props.image.height}
      />
      <p className={'title'}> {props.title} </p>
      <p className={'message'}> {props.message} </p>
      {props.btn && (
        <button className="card-button" {...props.btn}>
          {props.btn.text}
        </button>
      )}
      <MobileView>
        {props.mobileBtn && (
          <button className="card-button" {...props.mobileBtn}>
            {props.mobileBtn.text}
          </button>
        )}
      </MobileView>
    </div>
  );
};

export default Card;
