/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull mydoh-fe'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 4
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/mydoh/Mydoh%20-%20Prod/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/mydoh/Mydoh%20-%20Prod/implementation/mydoh-fe)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'mydohprod';

export const ApiKey: Record<Environment, string> = {
  mydohprod: ''
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '4',
    branch: 'main',
    source: 'mydoh-fe',
    versionId: 'f13ab932-e5d6-448b-ac97-3b7f9e17de1d'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * Mobile|Web based on the source channel for user
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | app, website |
   */
  "Acquired From"?: "app" | "website";
  /**
   * **True|False
   *  Only populated for user type child**
   */
  "Allowance Setup"?: boolean;
  /**
   * Possible Values EN or FR
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | en, fr |
   */
  "App language"?: "en" | "fr";
  /**
   * Value of the app version on the Setting Screen
   */
  "App Version"?: string;
  /**
   * **True|False
   *  Only populated for user type primary parent or coparent**
   */
  "Autoload Setup"?: boolean;
  /**
   * If user has turned on Facial or Touch Login
   */
  "Biometric Security"?: boolean;
  /**
   * Age in years
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Child Age"?: number;
  /**
   * **True|False True if IDVd**
   */
  "Child Verified"?: boolean;
  /**
   * **# of goals completed by the kid**
   */
  "Completed Goals"?: string;
  /**
   * Apple|Google|Others
   */
  "Digital Wallet"?: string;
  /**
   * True if user has coparent added to Mydoh.
   */
  "Has Coparent"?: boolean;
  /**
   * Has user opted in to receive marketing comms
   */
  "Mydoh Marketing Optin"?: boolean;
  /**
   * **# of children added to Mydoh who have accepted the invite**
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "Number of Children"?: number;
  /**
   * **# of transactions till date**
   */
  "Number of Spends"?: string;
  /**
   * **Date of onboarding Mydoh for parent, date of accepting invite for coparent & child YYYY-MM-DD**
   */
  "Onboard Date"?: string;
  /**
   * Alphanumeric value of onboarding promocode used by user
   */
  "Onboarding Promocode"?: string;
  /**
   * \# of successfull payouts
   */
  "Payout Count"?: string;
  /**
   * Tells which physical card a user has
   *  **Classic|Custom|NA**
   */
  "Physical Card Type"?: string;
  /**
   * Postal code for the user
   */
  "Postal Code"?: string;
  /**
   * True|False True if user has allowed push notifications
   */
  "Push Permission"?: boolean;
  /**
   * **Amount in Savings (Goals + general)
   *  Only for child user**
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Savings Balance"?: number;
  /**
   * **Date of registering with Mydoh for parent, invite date for coparent & child Format YYYY-MM-DD**
   */
  "Signup Date"?: string;
  /**
   * **# of tasks completed by the kid till date**
   */
  "Tasks Count"?: string;
  /**
   * True if user is marked as test user in DB
   */
  "Test User"?: boolean;
  /**
   * Test user property for backend validation
   */
  "Test User Property Backend"?: string;
  /**
   * **primaryparent, coparent or child**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Child, Parent, Co-Parent |
   */
  "User Type"?: "Child" | "Parent" | "Co-Parent";
  /**
   * **Amount of wallet balance
   *  Spend balance for Kids**
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "Wallet Balance"?: number;
  /**
   * True if user joined through referral
   */
  "Was User Referred"?: boolean;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
