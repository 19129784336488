export default {
  usernameHeader: {
    title: `Envoyez de l'argent à`,
  },
  legalFooter: {
    securedByRBC: 'Sécurisé par la Banque royale du Canada',
    infoCollection:
      'Nous collectons vos informations dans le but de faciliter le paiement et de transmettre des informations à nos utilisateurs.',
    privacyPolicies: 'Politiques de confidentialité de Mydoh',
    url: 'https://www.rbc.com/rensperssecurite/ca/avis-rens-pers-mondiale.html',
  },
  firstTimeMydoherBanner: {
    heading: `Première fois que vous entendez parler de Mydoh ?`,
    body:
      'Découvrez comment nous aidons les parents et les enfants à développer de bonnes habitudes financières.',
    url: `https://www.mydoh.ca/fr/argent-envoye-questceque-mydoh/`,
  },
  receiveMoneyForm: {
    amount: 'Montant',
    amountPlaceholder: 'Entrez le montant',
    sender: 'Envoyeur',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    emailAddress: 'Adresse courriel',
    personalNote: 'Message personel (optionnel)',
    personalNoteDisclaimer:
      "Le message sera vu par le parent ou tuteur légal du destinataire. Ne partagez pas d'informations personnelles.",
    payWithDebitCard: 'Payer par carte de débit',
    // Receive Money Form Validation
    validations: {
      amountTypeError: 'Le montant doit être un nombre.',
      required: 'Obligatoire',
      minimumAmount: 'Doit être au moins 5 $',
      maximumAmount: 'Maximum de 500 $',
      nameTypeError: 'Le nom doit être une chaîne de caractères.',
      minimumCharacters: 'Doit avoir au moins 2 lettres',
      maximumCharacters: 'Doit contenir au maximum 20 lettres.',
      invalidCharacter: "Notre processeur de paiement n'autorise pas les caractères spéciaux",
      checkEmail: 'Veuillez vérifiez que votre adresse e-mail est correcte',
      hasUrls: 'La note ne doit contenir que des mots, des chiffres ou de la ponctuation',
      hasBadWords: 'Votre note contient des mots restreints',
      noSpaces: `Ne doit contenir d'espaces`,
    },
  },
  receiveMoneyConfirmation: {
    title: 'Confirmer les détails',
    to: 'À',
    from: 'De',
    paymentMethod: 'Méthode de paiement',
    date: 'Date',
    sendMoneyDisclaimer: `Une fois envoyé, votre paiement sera <strong>final</strong> et ne pourra pas être annulé ou remboursé.`,
    sendNow: 'Envoyer maintenant',
    edit: 'Modifier',
    userFullName: '{{firstName}} {{lastNameInitial}}.',
    userFullNameAndEmail: `{{firstName}} {{lastName}} ({{email}})`,
    lastFourDigits: `•••• {{lastFourDigits}}`,
    today: `{{today}}`,
  },
  receiveMoneySuccess: {
    moneySentSuccessfullyHeading: `L'argent à été envoyé avec succès à {{firstName}} {{lastNameInitial}}.`,
    moneySentSuccessfullyBody: `Un reçu pour cette transaction sera envoyé à votre adresse e-mail.`,
  },
  receiveMoneyError: {
    accountLimitIssue: {
      title: `Problème de paiement`,
      body: `{{firstName}} ne peut pas recevoir de l'argent pour le moment. Contactez {{firstName}} ou un de ses parents pour de détails.`,
    },
    paymentIssue: {
      title: `Réessayez plus tard`,
      body: `Désolé, nous ne pouvons pas envoyer d'argent pour le moment. Veuillez réessayer dans une heure.<br/><br/><br/>Si le problème persiste, vous pouvez nous contacter à <mail>support@mydoh.ca.</mail>`,
    },
    serviceOutage: {
      title: `Impossible d'envoyer de l'argent`,
      body: `Désolé, nous ne pouvons pas envoyer d'argent pour le moment. <br/><br/><br/>Si le problème persiste, vous pouvez nous contacter à <mail>support@mydoh.ca.</mail> `,
    },
  },
};
