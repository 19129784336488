import Lottie from 'lottie-react';
import AnimationService from '../../../../services/AnimationService';
import './MydohLoader.scss';
const MydohLoader = () => {
  return (
    <div className="loader-container">
      <Lottie animationData={AnimationService.mloader} loop={true} className="animation-size" />
    </div>
  );
};

export default MydohLoader;
