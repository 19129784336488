export default {
  title: 'Create New Password',
  passwordLabel: 'New Password',
  confirmPasswordLabel: 'Re-enter New Password',
  placeholder: 'Enter your password here',
  button: 'Create New Password',
  errorTitle: 'Uh-oh',
  errorMsg: 'Looks like something went wrong. We were unable to change your password.',
  errorBtn: 'Try Again',
  successTitle: 'Success',
  successMsg: 'You’ve reset your password. Please log in to the app to continue.',
  successBtn: 'Log In to Mydoh',
  validatorTitle: 'Passwords must have',
  minChar: 'minimum 8 characters',
  capChar: '1 capital letter',
  numberChar: '1 number',
  specialChar: '1 special character',
  tooltip: 'valid characters:',
  complexityError: 'Password must meet complexity requirements.',
  matchingError: 'The passwords do not match. Please check and enter your password again.',
  tooltipAltText: 'Tap here for special characters you can use',
  linkExpiredTitle: 'TRY RESETTING YOUR PASSWORD AGAIN',
  linkExpiredMessage:
    'Your password reset link has expired or this link has already been used. Please go back to the app and submit a new request.',
  linkExpiredButton: 'Open App',
};
