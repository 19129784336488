import passwordReset from './passwordReset';
import common from './common';
import unblockAccount from './unblockAccount';
import monerisContent from './monerisContent';
import receiveMoney from './receiveMoney';

const en = {
  common,
  passwordReset,
  unblockAccount,
  monerisContent,
  receiveMoney,
};

export default en;
