import React, { useEffect, useState, useRef } from 'react';
import './Moneris.scss';
import { useFormik } from 'formik';
import { MonerisInputValidationSchema } from './FormInput.validation';
import MonerisForm from './MonerisForm';
import useMonerisForm from './MonerisHook';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { WithTranslation } from '../../lang/WithTranslation.hoc';
import closeToastIcon from '../../assets/icons/closeToastIcon.svg';

const Moneris = () => {
  const monerisRef = useRef(null);
  const { t } = useTranslation('monerisContent');

  const handleMessages = respData => {
    if (respData?.hasOwnProperty('cardholderName') && !isFormPrepopulated) {
      formik.setFieldValue('cardholderName', respData?.cardholderName || '');
      formik.setFieldValue('address', respData?.address || '');
      formik.setFieldValue('city', respData?.city || '');
      formik.setFieldValue(
        'postalCode',
        respData?.postalCode ? respData.postalCode.toUpperCase() : '',
      );
      formik.setFieldValue('province', respData?.province ? respData.province.toUpperCase() : '');
      setIsFormPrepopulated(true);
    }
    if (respData?.hasOwnProperty('responseCode')) {
      if (respData?.hasOwnProperty('dataKey')) {
        setDataKey(respData.dataKey);
      } else {
        handleVaultError(respData.responseCode);
      }
    } else if (respData?.hasOwnProperty('challengeData')) {
      setChallengeData(respData.challengeData);
      setChallengeUrl(respData.challengeUrl);
    }
  };

  const { handleVaultError, errors, handleFormSubmit, loading } = useMonerisForm(
    monerisRef,
    handleMessages,
  );

  const [dataKey, setDataKey] = useState(null);
  const [challengeData, setChallengeData] = useState(null);
  const [challengeUrl, setChallengeUrl] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [isFormPrepopulated, setIsFormPrepopulated] = useState(false);
  const formRef = useRef(null);
  const errorToast = errors => {
    const message =
      errors.length > 1
        ? t('error.cardMsg')
        : t('error.genericSingleErrorMsg', {
            erroredField: errors[0] !== t('cardCVDMsg') ? errors[0].toLowerCase() : errors[0],
          });
    return (
      <div className="toast-message">
        <div className="toast-message-header">
          <img src={closeToastIcon} alt={'Close'} />
          <p>{message}</p>
        </div>
        {errors.length > 1 ? (
          <ul>
            {errors.map((value, i) => (
              <li key={i}>{value}</li>
            ))}
          </ul>
        ) : null}
      </div>
    );
  };

  // If Visa auth needs 3DS, posts Visa auth form
  useEffect(() => {
    if (formRef.current) {
      formRef.current.submit();
    }
  }, [challengeUrl]);

  useEffect(() => {
    if (errors.length > 0) {
      toast.error(errorToast(errors), { toastId: '1' });
      formik.setSubmitting(false);
    }
    // Ignore React Hook useEffect has a missing dependency: 'formik' error
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    if (formValues && dataKey) {
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ dataKey: dataKey, formValues: formValues }),
      );
    }
  }, [formValues, dataKey]);

  const formik = useFormik({
    initialValues: {
      cardholderName: '',
      address: '',
      city: '',
      province: '',
      postalCode: '',
    },
    isInitialValid: false,
    validationSchema: MonerisInputValidationSchema,
    onSubmit: values => {
      setFormValues(values);
      handleFormSubmit(values);
      formik.setSubmitting(false);
    },
  });

  const disableButton = !formik.isValid || formik.isSubmitting || loading;

  return (
    <div className="moneris-container">
      <div id="monerisResponse"></div>
      <MonerisForm monerisRef={monerisRef} className={'moneris-webview form-height'} />
      <form onSubmit={formik.handleSubmit} className="form-container">
        <div>
          <label className="input-label">
            {t('form.cardHolderName')}
            <input
              type="text"
              name="cardholderName"
              className="input-fields"
              value={formik.values.cardholderName}
              onChange={formik.handleChange}
              onBlur={e => {
                //Trim extra white space before setting the value
                formik.setFieldValue('cardholderName', formik.values.cardholderName.trim());
                formik.handleBlur(e);
              }}
            />
          </label>
          {formik.touched.cardholderName && formik.errors.cardholderName && (
            <div className="error-message">{formik.errors.cardholderName}</div>
          )}
        </div>

        <div className="input-container">
          <label className="input-label">
            {t('form.address')}
            <input
              type="text"
              name="address"
              className="input-fields"
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </label>
          {formik.touched.address && formik.errors.address && (
            <div className="error-message">{formik.errors.address}</div>
          )}
        </div>

        <div className="input-container">
          <label className="input-label">
            {t('form.city')}
            <input
              type="text"
              name="city"
              className="input-fields"
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </label>
          {formik.touched.city && formik.errors.city && (
            <div className="error-message">{formik.errors.city}</div>
          )}
        </div>
        <div className="province-postal">
          <div className="input-container province">
            <label className="input-label">
              {t('form.province')}
              <input
                type="text"
                name="province"
                className="input-fields"
                value={formik.values.province.toUpperCase()}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </label>
            {formik.touched.province && formik.errors.province && (
              <div className="error-message">{formik.errors.province}</div>
            )}
          </div>

          <div className="input-container postalCode">
            <label className="input-label">
              {t('form.postalCode')}
              <input
                type="text"
                name="postalCode"
                className="input-fields"
                value={formik.values.postalCode.toUpperCase()}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </label>
            {formik.touched.postalCode && formik.errors.postalCode && (
              <div className="error-message">{formik.errors.postalCode}</div>
            )}
          </div>
        </div>
        <button
          type="submit"
          className={`form-button ${disableButton ? 'btn-disabled' : ''}`}
          disabled={disableButton}
        >
          {t('btn')}
        </button>
      </form>
      {challengeUrl && (
        <form method="POST" action={challengeUrl} ref={formRef}>
          <input name="creq" className="challenge-input" value={challengeData} />
        </form>
      )}
    </div>
  );
};
export default WithTranslation(Moneris);
