import coin from '../assets/images/coin.svg';
import mydohLogoHorizontal from '../assets/images/mydohLogoHorizontal.svg';
import coin_variationB from '../assets/images/coin-variation-b.svg';
import sendMoneyCoins from '../assets/images/send-money-coins.svg';
import mydohDebitCard from '../assets/images/mydoh-debit-card.svg';
import rbcLogo from '../assets/images/rbcLogo.png';
import paymentIssue from '../assets/images/payment-issue.svg';

const SvgService = {
  mydohLogoHorizontal,
  //Child Receive Payment Assets
  coin,
  coin_variationB,
  sendMoneyCoins,
  mydohDebitCard,
  rbcLogo,
  paymentIssue,
};

export type SvgServiceSvgNames = keyof typeof SvgService;

export default SvgService;
