import axios, { CancelToken } from 'axios';

type MydohChildName = {
  firstName: string;
  lastNameInitial: string;
};

type MonerisFormValues = {
  dataKey: string;
  cardholderName: string;
  address: string;
  city: string;
  province: string;
  postalCode: string;
  amount: number;
};

type Moneris3dAuthValues = MonerisFormValues & {
  userAgent: string;
  screenHeight: number;
  screenWidth: number;
  browserLanguage: string;
};

type MakePaymentParams = {
  payeeId: string;
  payerId: string;
  firstName: string;
  lastName: string;
  email: string;
  amount: string;
  expiryDate: string;
  message: string;
  lang: string;
};

type MonerisAuthParams = {
  payeeId: string;
  userAgent: string;
  dataKey: string;
  formValues: object;
  cancelToken: CancelToken;
};

export const getPayee = async (payeeId: string): Promise<MydohChildName> => {
  const response = await axios.get(`${process.env.REACT_APP_MYDOH_BE}/payment/payee/${payeeId}`);
  return response.data;
};

export const isPaymentAllowedForPayee = async (
  payeeId: string,
): Promise<{ isAllowed: boolean; name: MydohChildName | null }> => {
  let name = null;
  try {
    name = await getPayee(payeeId);
    const response = await axios.get(
      `${process.env.REACT_APP_MYDOH_BE}/payment/payee/${payeeId}/check-payment-allowed`,
    );
    return { isAllowed: !!response.data, name };
  } catch (error) {
    if (error?.response?.status === 503) {
      //only throw 503 so we can display to service unavailable state
      throw error;
    }
    return { isAllowed: false, name };
  }
};

//3d auths, challenges, and performs card verification
export const doMoneris3dAuthentication = async ({
  payeeId,
  userAgent,
  dataKey,
  formValues,
  cancelToken,
}: MonerisAuthParams): Promise<any> => {
  const {
    cardholderName,
    address,
    city,
    province,
    postalCode,
    screenHeight,
    screenWidth,
    browserLanguage,
    amount,
    firstName,
    lastName,
    email,
    note,
  } = formValues as Moneris3dAuthValues;
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_MYDOH_BE}/payment/3dauth/${payeeId}`,
      {
        dataKey,
        cardholderName,
        address,
        city,
        province,
        postalCode,
        userAgent,
        screenHeight,
        screenWidth,
        amount: Number(amount),
        message: note,
        redirectUrl: `${payeeId}`,
        payer: {
          firstName,
          lastName,
          email,
          lang: browserLanguage,
        },
      },
      {
        cancelToken,
      },
    );
    if (!response) {
      throw new Error(`No response received`);
    }
    return response.data;
  } catch (error) {
    if (axios.isCancel(error)) {
      console.log(`/3dauth Component has unmounted, cancelling request`);
      return;
    }
    console.log(`Unable to do 3D authentication for payee=[${payeeId}]`, error);
    throw error;
  }
};

export const makePayment = async ({
  payeeId,
  payerId,
  firstName,
  lastName,
  email,
  amount,
  expiryDate,
  message,
  lang,
}: MakePaymentParams): Promise<boolean> => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_MYDOH_BE}/payment/pay/${payeeId}`, {
      payerId,
      firstName,
      lastName,
      email,
      amount,
      expiryDate,
      message,
      lang,
    });
    if (!response?.data) {
      throw new Error('Card declined');
    }
    return response.data;
  } catch (error) {
    console.log(`Unable to make payment for payeeId=[${payeeId}] payerId=[${payerId}]`);
    throw error;
  }
};

export const cavvAndVerification = async ({ payerId }: { payerId: string }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_MYDOH_BE}/payment/cavv-and-verification/${payerId}`,
    );
    return response.data;
  } catch (error) {
    console.error(`Unable to verify card for payerId=[${payerId}]`, error);
    throw error;
  }
};
