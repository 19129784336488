import { Form, Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../components/button/Button';
import FormInput from '../../components/input/FormInput';
import { PasswordInputValidationSchema } from '../../components/passwordValidator/PasswordInput.validation';
import PasswordValidator from '../../components/passwordValidator/PasswordValidator';

const PasswordResetForm = props => {
  const { t } = useTranslation('passwordReset');

  const handleSubmit = async values => {
    props.handleSubmit(values);
  };

  return (
    <Formik
      initialValues={{ password: '', passwordConfirm: '' }}
      validationSchema={PasswordInputValidationSchema}
      validateOnBlur={false}
      validateOnMount={true}
      onSubmit={values => {
        handleSubmit(values);
      }}
    >
      {props => (
        <Form className="password-form-container">
          <FormInput
            label={t('passwordLabel')}
            name="password"
            type="password"
            placeholder={t('placeholder')}
            secureTextEntry={true}
            testProps={props}
          />

          <PasswordValidator password={props.values.password} />

          <FormInput
            label={t('confirmPasswordLabel')}
            name="passwordConfirm"
            type="password"
            placeholder={t('placeholder')}
            secureTextEntry={true}
          />
          <Button
            className="form-button"
            type={'submit'}
            text={t('button')}
            disabled={!props.isValid}
          />
        </Form>
      )}
    </Formik>
  );
};

export default PasswordResetForm;
