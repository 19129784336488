import React, { useState, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import {
  ReceiveMoneyErrorType,
  ReceiveMoneyWizardState,
  ReceiveMoneyWizardStep,
} from './ReceiveMoneyWizard';
import { makePayment } from '../../services/childReceivePayments.actions';
import './ReceiveMoneyForm.scss';
import './ReceiveMoneyConfirmation.scss';
import SvgService from '../../services/SvgService';
import Button from '../../components/button/Button';
import { Trans, useTranslation } from 'react-i18next';
import { WithTranslation } from '../../lang/WithTranslation.hoc';

export type ReceiveMoneyConfirmationProps = {
  payeeId: string;
  receiveMoneyForm: ReceiveMoneyWizardState;
  onSubmit: (index: ReceiveMoneyWizardStep, errorType?: ReceiveMoneyErrorType) => void;
};

const ReceiveMoneyConfirmation = ({
  payeeId,
  receiveMoneyForm,
  onSubmit,
}: ReceiveMoneyConfirmationProps) => {
  const { t } = useTranslation('receiveMoney');
  const [isLoading, setIsLoading] = useState(false);
  const {
    username,
    amount,
    firstName,
    lastName,
    email,
    note,
  } = receiveMoneyForm.RECEIVE_MONEY_FORM;
  const lang = navigator.language;
  const { lastFourDigits, expiryDate, payerId } = receiveMoneyForm.MONERIS_FORM;
  const langFormat = lang.includes('en') ? 'en-CA' : 'fr-CA';
  const formattedAmount = new Intl.NumberFormat(langFormat, {
    style: 'currency',
    currency: 'CAD',
    currencyDisplay: 'symbol',
  }).format(Number(amount));

  const today = new Date().toLocaleDateString(langFormat, {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const sendMoney = async () => {
    setIsLoading(true);
    try {
      await makePayment({
        payeeId,
        payerId,
        firstName,
        lastName,
        email,
        amount,
        expiryDate,
        message: note,
        lang,
      });

      setIsLoading(false);
      onSubmit('RECEIVE_MONEY_SUCCESS');
    } catch (error) {
      if (error?.response?.status === 503) {
        onSubmit('RECEIVE_MONEY_ERROR', 'SERVICE_OUTAGE');
      }
      onSubmit('RECEIVE_MONEY_ERROR', 'PAYMENT_ISSUE');
    }
  };

  const onEdit = () => {
    //sends event tracking to Google Tags Manager
    TagManager.dataLayer({
      dataLayer: {
        payeeId: payeeId,
        event: 'cta_click',
        category: 'receive payment link flow',
        action: 'cta clicked',
        label: 'Edit',
        ux_element_metadata: 'receive-payment _link_edit_cta',
      },
    });

    onSubmit('RECEIVE_MONEY_FORM');
  };

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        payeeId: payeeId,
        event: 'virtual_pageview',
        virtual_page_url: 'vpv/receive-payment-link-flow/step3/confirm-details',
        virtual_page_title: 'Confirm Details',
        pageContentType: 'receive payment link flow',
      },
    };
    //sends tracking data to Google Tags Manager
    TagManager.dataLayer(tagManagerArgs);
  }, [payeeId]);

  return (
    <div className="receive-money-form form-width">
      <h1 className="heading-label">{t('receiveMoneyConfirmation.title')}</h1>
      <div className="confirmation-container block-padding">
        <img src={SvgService.coin_variationB} alt="coin" className="coin-variation-b--img" />
        <p className="amount--p">{formattedAmount}</p>
        {note ? <p className="personal-note--p">{note} </p> : null}
      </div>
      <div className="confirmation-container payer-information-container">
        <table>
          <tbody>
            <tr>
              <th>{t('receiveMoneyConfirmation.to')}</th>
              <td>
                {t('receiveMoneyConfirmation.userFullName', {
                  firstName: username.firstName,
                  lastNameInitial: username.lastNameInitial,
                })}
              </td>
            </tr>
            <tr>
              <th>{t('receiveMoneyConfirmation.from')}</th>
              <td>
                {t('receiveMoneyConfirmation.userFullNameAndEmail', {
                  firstName,
                  lastName,
                  email,
                })}
              </td>
            </tr>
            <tr>
              <th>{t('receiveMoneyConfirmation.paymentMethod')}</th>
              <td>{t('receiveMoneyConfirmation.lastFourDigits', { lastFourDigits })}</td>
            </tr>
            <tr>
              <th>{t('receiveMoneyConfirmation.date')}</th>
              <td>{t('receiveMoneyConfirmation.today', { today })}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="send-money-disclaimer">
        <p>
          <Trans
            i18nKey={'receiveMoney:receiveMoneyConfirmation.sendMoneyDisclaimer'}
            components={{
              strong: <strong />,
            }}
          />
        </p>
      </div>
      <div className="button-container">
        <Button
          variant="secondary"
          disabled={isLoading}
          onClick={onEdit}
          text={t('receiveMoneyConfirmation.edit')}
        />

        <Button
          isLoading={isLoading}
          disabled={isLoading}
          onClick={() => sendMoney()}
          text={t('receiveMoneyConfirmation.sendNow')}
        />
      </div>
    </div>
  );
};

export default WithTranslation(ReceiveMoneyConfirmation);
