export default {
  btn: 'Continue',
  form: {
    panLabel: 'Card Number',
    expLabel: 'Expiry Date MM/YY',
    cvdLabel: 'CVV',
    cardHolderName: 'Cardholder Name',
    address: 'Address',
    city: 'City',
    province: 'Province',
    postalCode: 'Postal Code',
  },
  formValidation: {
    required: 'Required',
    minimumCharacters: 'Must be at least {{minLength}} characters',
    maximumCharacters: 'Must be maximum {{maxLength}} characters',
    invalidCharacter: 'Our payment processor does not allow special characters',
  },
  error: {
    cardMsg: 'Please review the following information and try again:',
    genericMsg: 'Something went wrong, please review your card information and try again.',
    cardNumberMsg: 'Card number',
    cardExpMsg: 'Expiry date',
    cardCVDMsg: 'CVV',
    genericSingleErrorMsg: `Please check that you have the correct {{erroredField}} and try again.`,
  },
  monerisWebForm: {
    title: 'Pay with Visa Debit or Debit Mastercard',
  },
};
