import applicationRequiredImage from '../../assets/images/applicationRequiredImage.svg';

export default {
  image: {
    source: applicationRequiredImage,
    width: 350,
    className: 'applogin-image',
  },
  title: 'App Login Required',
  message:
    'Please log in to the app to add funds, invite kids, send money or set up tasks for your kids.',
};
