import React from 'react';
import './Header.scss';
import HeaderContent from './Header.content';

const Header = () => {
  return (
    <div className="header">
      <img
        src={HeaderContent.image.source}
        alt={HeaderContent.image.alt}
        width={HeaderContent.image.width}
        height={HeaderContent.image.height}
        className="header__image"
      />
    </div>
  );
};

export default Header;
