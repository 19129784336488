import React, { useEffect, useState, useRef } from 'react';
import TagManager from 'react-gtm-module';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import './ReceiveMoneyForm.scss';
import '../Moneris/Moneris.scss';
import { MonerisInputValidationSchema } from '../Moneris/FormInput.validation';
import MonerisForm from '../Moneris/MonerisForm';
import useMonerisForm from '../Moneris/MonerisHook';
import closeToastIcon from '../../assets/icons/closeToastIcon.svg';
import { WithTranslation } from '../../lang/WithTranslation.hoc';
import {
  ReceiveMoneyErrorType,
  ReceiveMoneyWizardState,
  ReceiveMoneyWizardStep,
} from './ReceiveMoneyWizard';
import { doMoneris3dAuthentication } from '../../services/childReceivePayments.actions';
import axios from 'axios';
import Button from '../../components/button/Button';

type MonerisWebFormProps = {
  payeeId: string;
  receiveMoneyForm: ReceiveMoneyWizardState;
  onSubmit: (
    index: ReceiveMoneyWizardStep,
    {
      cardholderName,
      address,
      city,
      province,
      postalCode,
      dataKey,
      lastFourDigits,
      payerId,
      expiryDate,
    }: ReceiveMoneyWizardState['MONERIS_FORM'],
    errorType?: ReceiveMoneyErrorType,
  ) => void;
};

const MonerisWebForm = ({ payeeId, receiveMoneyForm, onSubmit }: MonerisWebFormProps) => {
  const monerisRef = useRef(null);
  const formRef = useRef(null);
  const { t } = useTranslation('monerisContent');

  const [dataKey, setDataKey] = useState(null);
  const [challengeData, setChallengeData] = useState(null);
  const [challengeUrl, setChallengeUrl] = useState(null);
  const [maskedPan, setMaskedPan] = useState(null);
  const [payerId, setPayerId] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      cardholderName: '',
      address: '',
      city: '',
      province: '',
      postalCode: '',
    },
    validateOnMount: true,
    validationSchema: MonerisInputValidationSchema,
    onSubmit: values => {
      handleFormSubmit(values);
      formik.setSubmitting(false);
    },
  });

  const handleMessages = responseData => {
    const { setFieldValue } = formik;
    if (responseData.hasOwnProperty('cardholderName')) {
      const { cardholderName, address, city, postalCode, province } = responseData;
      setFieldValue('cardholderName', cardholderName ?? '');
      setFieldValue('address', address ?? '');
      setFieldValue('city', city || '');
      setFieldValue('postalCode', postalCode?.toUpperCase() ?? '');
      setFieldValue('province', province?.toUpperCase() ?? '');
    }

    if (responseData.hasOwnProperty('responseCode')) {
      if (responseData.hasOwnProperty('dataKey')) {
        setDataKey(responseData.dataKey);
      } else {
        handleVaultError(responseData.responseCode);
      }
      if (responseData.hasOwnProperty('maskedPan')) {
        setMaskedPan(responseData.maskedPan);
      }
    }

    if (responseData.hasOwnProperty('challengeData')) {
      setChallengeData(responseData.challengeData);
      setChallengeUrl(responseData.challengeUrl);
    }
  };

  const { handleVaultError, errors, handleFormSubmit, loading } = useMonerisForm(
    monerisRef,
    handleMessages,
  );

  const handleSubmit = async (dataKey, cancelToken) => {
    try {
      const response = await doMoneris3dAuthentication({
        payeeId,
        userAgent: window.navigator.userAgent,
        dataKey,
        formValues: { ...receiveMoneyForm['RECEIVE_MONEY_FORM'], ...formik.values },
        cancelToken,
      });

      //if the request has been cancelled because the component unmounted, do not continue with the rest of the function
      if (!response) {
        return;
      }

      const {
        transStatus,
        challengeURL,
        challengeData,
        payer,
        maskedPan,
        cardExpiryDate,
      } = response;
      if (payer) {
        setPayerId(payer.payerId);
      }
      if (maskedPan) {
        setMaskedPan(maskedPan);
      }
      if (cardExpiryDate) {
        setExpiryDate(cardExpiryDate);
      }
      //TransStatus 'C' means a user is required to compelete a challenge
      if (transStatus === 'C') {
        setChallengeData(challengeData);
        setChallengeUrl(challengeURL);

        window?.postMessage(
          JSON.stringify({
            challengeUrl,
            challengeData,
          }),
        );
      }
    } catch (error) {
      if (error?.response?.status === 503) {
        onSubmit('RECEIVE_MONEY_ERROR', 'SERVICE_OUTAGE');
      }
      onSubmit('RECEIVE_MONEY_ERROR', 'PAYMENT_ISSUE');
    }
  };

  //TODO: move this out to a seperate component
  const errorToast = errors => {
    const message =
      errors.length > 1
        ? t('error.cardMsg')
        : t('error.genericSingleErrorMsg', {
            erroredField: errors[0] !== t('cardCVDMsg') ? errors[0].toLowerCase() : errors[0],
          });
    return (
      <div className="toast-message">
        <div className="toast-message-header">
          <img src={closeToastIcon} alt={'Close'} />
          <p>{message}</p>
        </div>
        {errors.length > 1 ? (
          <ul>
            {errors.map((value, i) => (
              <li key={i}>{value}</li>
            ))}
          </ul>
        ) : null}
      </div>
    );
  };

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        payeeId: payeeId,
        event: 'virtual_pageview',
        virtual_page_url: 'vpv/receive-payment-link-flow/step2/pay-with-debit-mastercard',
        virtual_page_title: 'Pay with debit or mastercard card',
        pageContentType: 'receive payment link flow',
      },
    };
    //sends tracking data to Google Tags Manager
    TagManager.dataLayer(tagManagerArgs);
  }, [payeeId]);

  // If Visa auth needs 3DS, posts Visa auth form
  useEffect(() => {
    if (formRef.current) {
      formRef.current.submit();
    }
  }, [challengeUrl]);

  useEffect(() => {
    if (errors.length > 0) {
      toast.error(errorToast(errors), { toastId: '1' });
      formik.setSubmitting(false);
    }

    // Ignore React Hook useEffect has a missing dependency: 'formik' error
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  // check if there is a data key, maskedPan, and payerId
  // if all of these values are set, user's card was successfully verified and we can move to the next page.
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    if (dataKey && dataKey !== '' && maskedPan !== '' && !payerId && !maskedPan) {
      setIsLoading(true);
      handleSubmit(dataKey, source.token);
      return;
    }
    return () => {
      source.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataKey, maskedPan]);

  useEffect(() => {
    if (dataKey && maskedPan && payerId && expiryDate) {
      setIsLoading(false);
      onSubmit('RECEIVE_MONEY_CONFIRMATION', ({
        ...formik.values,
        dataKey,
        lastFourDigits: maskedPan,
        payerId,
        expiryDate,
      } as unknown) as ReceiveMoneyWizardState['MONERIS_FORM']);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataKey, maskedPan, payerId, expiryDate]);

  return (
    <div className=" moneris-container form-width receive-money-form">
      <p className="heading-label web-form-heading">{t('monerisWebForm.title')}</p>
      <div id="monerisResponse"></div>
      <MonerisForm monerisRef={monerisRef} className={'moneris-webview form-height'} />
      <form onSubmit={formik.handleSubmit} className="form-container">
        <div>
          <label className="input-label">
            {t('form.cardHolderName')}
            <input
              type="text"
              name="cardholderName"
              className="input-fields"
              value={formik.values.cardholderName}
              onChange={formik.handleChange}
              onBlur={e => {
                //Trim extra white space before setting the value
                formik.setFieldValue('cardholderName', formik.values.cardholderName.trim());
                formik.handleBlur(e);
              }}
            />
          </label>
          {formik.touched.cardholderName && formik.errors.cardholderName && (
            <div className="error-message">{formik.errors.cardholderName}</div>
          )}
        </div>

        <div className="input-container">
          <label className="input-label">
            {t('form.address')}
            <input
              type="text"
              name="address"
              className="input-fields"
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </label>
          {formik.touched.address && formik.errors.address && (
            <div className="error-message">{formik.errors.address}</div>
          )}
        </div>

        <div className="input-container">
          <label className="input-label">
            {t('form.city')}
            <input
              type="text"
              name="city"
              className="input-fields"
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </label>
          {formik.touched.city && formik.errors.city && (
            <div className="error-message">{formik.errors.city}</div>
          )}
        </div>
        <div className="province-postal">
          <div className="input-container province">
            <label className="input-label">
              {t('form.province')}
              <input
                type="text"
                name="province"
                className="input-fields"
                value={formik.values.province.toUpperCase()}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </label>
            {formik.touched.province && formik.errors.province && (
              <div className="error-message">{formik.errors.province}</div>
            )}
          </div>

          <div className="input-container postalCode">
            <label className="input-label">
              {t('form.postalCode')}
              <input
                type="text"
                name="postalCode"
                className="input-fields"
                value={formik.values.postalCode.toUpperCase()}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </label>
            {formik.touched.postalCode && formik.errors.postalCode && (
              <div className="error-message">{formik.errors.postalCode}</div>
            )}
          </div>
        </div>
        <Button
          text="Continue"
          type="submit"
          className="web-form-button"
          disabled={!formik.isValid || formik.isSubmitting || loading}
          isLoading={isLoading}
        />
      </form>
      {challengeUrl ? (
        <form method="POST" action={challengeUrl} ref={formRef}>
          <input
            name="creq"
            className="challenge-input"
            value={challengeData}
            readOnly
            type="hidden"
          />
        </form>
      ) : null}
    </div>
  );
};

export default WithTranslation(MonerisWebForm);
