import React from 'react';
import './Button.scss';

interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  text: string;
  disabled?: boolean;
  isLoading?: boolean;
  variant?: 'primary' | 'secondary';
}

const Button = ({ disabled, text, variant, isLoading, className, ...props }: ButtonProps) => {
  return (
    <button
      className={`mydoh-button 
        ${variant === 'secondary' ? 'secondary' : ''}
        ${isLoading ? 'mydoh-button--loading' : ''}  
        ${disabled ? 'btn-disabled' : ''} 
        ${className}`}
      disabled={disabled}
      {...props}
    >
      <span className="mydoh-button--text">{text}</span>
    </button>
  );
};

export default Button;
