import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import SvgService from '../../services/SvgService';
import './ReceiveMoneyForm.scss';
import './ReceiveMoneySuccess.scss';
import FirstTimeMydoherBanner from './components/FirstTimeMydoherBanner/FirstTimeMydoherBanner';
import { useTranslation } from 'react-i18next';
import { WithTranslation } from '../../lang/WithTranslation.hoc';

type ReceiveMoneySuccessProps = {
  payeeId: string;
  username: {
    firstName: string;
    lastNameInitial: string;
  };
};

const ReceiveMoneySuccess = ({ payeeId, username }: ReceiveMoneySuccessProps) => {
  const { t } = useTranslation('receiveMoney');
  const { firstName, lastNameInitial } = username;

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        payeeId: payeeId,
        event: 'virtual_pageview',
        virtual_page_url: 'vpv/receive-payment-link-flow/step4/money-successfully-sent',
        virtual_page_title: 'Money successfully sent',
        pageContentType: 'receive payment link flow',
      },
    };
    //sends tracking data to Google Tags Manager
    TagManager.dataLayer(tagManagerArgs);
  }, [payeeId]);

  return (
    <div className="form-width success-container">
      <img src={SvgService.sendMoneyCoins} alt="send money" className="send-money-coins--img" />
      <p className="heading-success">
        {t('receiveMoneySuccess.moneySentSuccessfullyHeading', { firstName, lastNameInitial })}
      </p>
      <p className="success-body-text">{t('receiveMoneySuccess.moneySentSuccessfullyBody')}</p>
      <FirstTimeMydoherBanner />
    </div>
  );
};

export default WithTranslation(ReceiveMoneySuccess);
