export default {
  usernameHeader: {
    title: `Send money to`,
  },
  legalFooter: {
    securedByRBC: 'Secured by the Royal Bank of Canada',
    infoCollection:
      'We are collecting your information for the purposes of facilitating payment and passing information to our users.',
    privacyPolicies: 'Mydoh’s privacy policies',
    url: 'https://www.rbc.com/privacysecurity/ca/global-privacy-notice.html',
  },
  firstTimeMydoherBanner: {
    heading: `First time hearing about Mydoh?`,
    body: 'Learn more about what we do to help parents and kids build better money habits early.',
    url: `https://mydoh.ca/money-sent-what-is-mydoh`,
  },
  receiveMoneyForm: {
    amount: 'Amount',
    amountPlaceholder: 'Enter amount',
    sender: 'Sender',
    firstName: 'First name',
    lastName: 'Last name',
    emailAddress: 'Email address',
    personalNote: 'Personal note (optional)',
    personalNoteDisclaimer:
      'Note will be seen by the recipient’s parent or legal guardian. Don’t share personal information.',
    payWithDebitCard: 'Pay with Debit Card',
    // Receive Money Form Validation
    validations: {
      amountTypeError: 'Amount must be a number',
      required: 'Required',
      minimumAmount: 'Must be at least $5',
      maximumAmount: 'Maximum of $500',
      nameTypeError: 'Name must be a string',
      minimumCharacters: 'Must be at least 2 characters',
      maximumCharacters: 'Must be maximum 20 characters',
      invalidCharacter: 'Our payment processor does not allow special characters',
      checkEmail: 'Check that your email is correct',
      hasUrls: 'Note should be only words, numbers, or punctuation',
      hasBadWords: 'Your note contains restricted words',
      noSpaces: 'Must not contain spaces',
    },
  },
  receiveMoneyConfirmation: {
    title: 'Confirm details',
    to: 'To',
    from: 'From',
    paymentMethod: 'Payment method',
    date: 'Date',
    sendMoneyDisclaimer: ` Once sent, your payment is <strong>final</strong> and cannot be cancelled or refunded.`,
    sendNow: 'Send now',
    edit: 'Edit',
    userFullName: '{{firstName}} {{lastNameInitial}}.',
    userFullNameAndEmail: `{{firstName}} {{lastName}} ({{email}})`,
    lastFourDigits: `•••• {{lastFourDigits}}`,
    today: `{{today}}`,
  },
  receiveMoneySuccess: {
    moneySentSuccessfullyHeading: `Money successfully sent to {{firstName}} {{lastNameInitial}}.`,
    moneySentSuccessfullyBody: `A receipt for this transaction will be sent to your email.`,
  },
  receiveMoneyError: {
    accountLimitIssue: {
      title: `Payment Issue`,
      body: `{{firstName}} is unable to receive money at the moment. Reach out to {{firstName}} or their parent
      for details.`,
    },
    paymentIssue: {
      title: `Try again later`,
      body: `Sorry, we're unable to send money at this moment. Please try again in an hour.<br/><br/><br/>If the issue persists, you can contact us at <mail>support@mydoh.ca.</mail>`,
    },
    serviceOutage: {
      title: `Unable to send money`,
      body: `Sorry, we’re unable to send money at the moment.<br/><br/><br/>If the issue persists, you can contact us at <mail>support@mydoh.ca.</mail> `,
    },
  },
};
