import * as React from 'react';
import { WithTranslation } from '../../../../lang/WithTranslation.hoc.jsx';
import SvgService from '../../../../services/SvgService';
import './LegalFooter.scss';
import '../../ReceiveMoneyForm.scss';
import { useTranslation } from 'react-i18next';

type LegalFooterProps = {
  isSuccessScreen?: boolean;
};

const LegalFooter = ({ isSuccessScreen }: LegalFooterProps) => {
  const { t } = useTranslation('receiveMoney');
  return (
    <div className={`form-width legal-footer-container ${isSuccessScreen ? 'color-blue' : ''}`}>
      <div className="secured-by-rbc-header">
        <img className="secured-by-rbc-header--image" src={SvgService.rbcLogo} alt="abc"></img>
        <p className={`${isSuccessScreen ? 'success-screen' : ''} secured-by-rbc-header-text`}>
          {t('legalFooter.securedByRBC')}
        </p>
      </div>
      <p className={`legal-footer-body-text ${isSuccessScreen ? 'success-screen' : ''}`}>
        {t('legalFooter.infoCollection')}
      </p>
      <a
        className={`privacy-policies--a ${isSuccessScreen ? 'success-screen' : ''}`}
        href={`${t('legalFooter.url')}`}
        target="_blank"
        rel="noreferrer"
      >
        {t('legalFooter.privacyPolicies')}
      </a>
    </div>
  );
};

export default WithTranslation(LegalFooter);
