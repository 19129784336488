import React from 'react';
import errorExclamation from '../../assets/images/errorExclamation.svg';
import FormCard from '../../components/card/FormCard';
import './ErrorCard.scss';
const ErrorCard = ({ title, message, btn }) => {
  return (
    <FormCard>
      <div className="activation__error-content">
        <img src={errorExclamation} alt="" className="error-img" />
        <p className="title">{title}</p>
        <p className="message">{message}</p>
        {btn && (
          <button className="mydoh-button error-card__button" {...btn}>
            {btn.text}
          </button>
        )}
      </div>
    </FormCard>
  );
};

export default ErrorCard;
