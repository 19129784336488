import * as React from 'react';
import { WithTranslation } from '../../../../lang/WithTranslation.hoc';
import SvgService from '../../../../services/SvgService';
import './UsernameHeader.scss';
import { useTranslation } from 'react-i18next';

type UsernameHeaderProps = {
  username: string;
};

const UsernameHeader = ({ username }: UsernameHeaderProps) => {
  const { t } = useTranslation('receiveMoney');
  return (
    <div className="username-header-container">
      <div className="text-container">
        <p className="username-header-label">{t('receiveMoney:usernameHeader.title')}</p>
        <p className="username-text">{username}</p>
      </div>
      <img src={SvgService.coin} alt="coin" className="coin-img" />
    </div>
  );
};

export default WithTranslation(UsernameHeader);
