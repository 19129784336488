import confirm from '../../assets/images/confirm.png';

export default {
  image: {
    source: confirm,
    width: 125,
    height: 125,
  },
  title: 'You’re almost there!',
  message:
    'Please click on the link from a mobile device to sign up for Mydoh. Note that Mydoh is currently only available for iOS.',
};
