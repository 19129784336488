import React from 'react';
import Card from '../../components/card/Card';
import Header from '../../components/header/Header';
import BackgroundWrapper from '../BackgroundWrapper/BackgroundWrapper';
import ApplicationRequiredContent from './ApplicationRequired.content';

const ApplicationRequired = () => {
  return (
    <BackgroundWrapper cssClass={`background bgform`}>
      <Header />
      <Card {...ApplicationRequiredContent} />
    </BackgroundWrapper>
  );
};

export default ApplicationRequired;
