import React from 'react';
import './MydohInput.scss';

type MydohInputProps = {
  isTouched: boolean;
  error: string;
  placeholder: string;
  inputProps: Partial<HTMLInputElement>;
};

// NOTE: In order for the floating label to show, you must include the placeholder property

const MydohInput = ({ isTouched, error, placeholder, ...inputProps }: MydohInputProps) => {
  return (
    <div className="mydoh-input-group">
      <div className="input-container">
        <input
          id="mydoh-input"
          className={`${
            isTouched && error ? 'mydoh-input-box mydoh-input-box--error' : 'mydoh-input-box'
          }`}
          placeholder={placeholder}
          {...inputProps}
        />
        <label htmlFor="mydoh-input" className="label">
          {placeholder}
        </label>
        <p className="error-message">{isTouched && error ? error : null}</p>
      </div>
    </div>
  );
};

export default MydohInput;
