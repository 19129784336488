import { ENGLISH_SUPPORT_EMAIL } from '../../constants/general';

export default {
  defaultError: {
    title: 'UNABLE TO VERIFY',
    message: `Sorry, we were not able to verify your sign-in attempt. Please contact our Customer Support team (<emailLink>${ENGLISH_SUPPORT_EMAIL}</emailLink>) for assistance.`,
  },
  success: {
    title: 'SUCCESS',
    message: 'A new device has now been added to your Mydoh account.',
    message2: 'Please log in to the app to continue.',
    button: 'Log In',
  },
  linkExpired: {
    title: 'TRY AGAIN',
    message:
      'Your verification link has expired or this link has already been used. Please go back to the app and sign in to request a new link.',
    button: 'Open App',
  },
};
