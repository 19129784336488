import mydohLogo from '../../assets/images/mydohLogoHorizontal.svg';

const handleBackToApp = () => {
  const { REACT_APP_PREFIX } = process.env;
  window.location.href = `${REACT_APP_PREFIX}signin`;
};

export default {
  logo: {
    source: mydohLogo,
  },
  mobileBtn: {
    text: 'Log In',
    onClick: handleBackToApp,
  },
};
