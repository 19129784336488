import React, { useState } from 'react';
import './FormInput.scss';
import { useField, useFormikContext } from 'formik';
import iconsEyeOn from '../../assets/icons/iconEyeOn.svg';
import iconsEyeOff from '../../assets/icons/iconEyeOff.svg';
import ErrorMessage from '../error/Error';

const FormInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const { errors } = useFormikContext();
  const { name, type, placeholder, secureTextEntry } = props;
  const [showPassword, setShowPassword] = useState(true);
  const [inputType, setInputType] = useState(type);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
    setInputType(inputType === 'text' ? 'password' : 'text');
  };

  const checkError = fieldName => {
    switch (fieldName) {
      case 'password':
        return false;
      case 'passwordConfirm':
        if (meta.touched && errors[field.name]) {
          return true;
        }
        return false;
      default:
        return meta.touched && meta.error;
    }
  };

  return (
    <div className="input-container">
      <div className={`input-box ${checkError(name) ? 'error' : ''}`}>
        <input
          {...field}
          name={name}
          type={inputType}
          placeholder={placeholder}
          className={`mydoh-input ${checkError(name) ? 'error' : 'valid'}`}
        />
        <label htmlFor={name} className="form-label">
          {label}
        </label>
        <button type="button" onClick={() => handleShowPassword(name)} className="eye-open-btn">
          {secureTextEntry &&
            (showPassword ? (
              <img className="form-icon-eye" src={iconsEyeOn} alt={'eye icon off'} />
            ) : (
              <img className="form-icon-eye" src={iconsEyeOff} alt={'eye icon'} />
            ))}{' '}
        </button>
      </div>
      {checkError(name) ? <ErrorMessage message={meta.error} /> : null}
    </div>
  );
};

export default FormInput;
