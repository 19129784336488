import React, { useState } from 'react';
import './PasswordValidator.scss';
import { passwordValidations } from './PasswordInput.validation';
import Tooltip from '../tooltip/Tooltip';
import iconsMoreInfo from '../../assets/icons/iconsMoreInfo.svg';
import { useTranslation } from 'react-i18next';

const PasswordValidator = ({ password }) => {
  const { t } = useTranslation('passwordReset');
  const touchedInput = password !== undefined;
  const [showTooltip, setShowTooltip] = useState(false);

  const showTooltipHandler = () => {
    setShowTooltip(!showTooltip);
  };

  return (
    <div className="password-validator">
      <div className="content">
        <span>{t('validatorTitle')}</span>
        {passwordValidations.map(({ key, label, validator }, index) => {
          const isValid = touchedInput && validator(password);
          return (
            <div key={key} className="validation-item">
              <div className={`validation-checkbox ${isValid ? 'valid' : 'invalid'}`}></div>
              <span className="validation-label">{t(key)}</span>
            </div>
          );
        })}
      </div>

      <div className="validator-tooltip">
        <button type="button" onClick={() => showTooltipHandler()} className="tooltip-btn">
          <img src={iconsMoreInfo} alt={t('tooltipAltText')} />
          {showTooltip && (
            <div className="tooltip-bubble">
              <Tooltip />
            </div>
          )}
        </button>
      </div>
    </div>
  );
};

export default PasswordValidator;
