import { FRENCH_SUPPORT_EMAIL } from '../../constants/general';

export default {
  defaultError: {
    title: 'INCAPABLE DE VÉRIFIER',
    message: `Désolé, nous ne sommes pas en mesure de vérifier votre tentative de connexion. Veuillez communiquer avec l'équipe du service à la clientèle (<emailLink>${FRENCH_SUPPORT_EMAIL}</emailLink> ) pour de l'aide.`,
  },
  success: {
    title: 'SUCCÈS',
    message: 'Un nouvel appareil a été ajouté à votre compte Mydoh.',
    message2: "Veuillez vous connecter à l'appli pour continuer",
    button: 'Se connecter à Mydoh',
  },
  linkExpired: {
    title: 'Essayer à nouveau',
    message: `Votre lien de vérification a expiré ou ce lien a déjà été utilisé. Veuillez retourner à l'application et vous connecter pour demander un nouveau lien.`,
    button: `Ouvrir l'application`,
  },
};
