import React from 'react';
import { MobileView } from 'react-device-detect';
import './ActivateAccount.scss';
import mydohLogoVertical from '../../assets/images/mydohLogoVertical.svg';
import mydohLogoHorizontal from '../../assets/images/mydohLogoHorizontal.svg';

const ActivateCard = ({ title, message, message2, logo, btn, mobileBtn }) => {
  return (
    <div className="request-success">
      <div className="content">
        <img src={mydohLogoVertical} alt="" className="activation-logo__top" />
        <p className={'title'}> {title} </p>
        <span className={'message'}> {message} </span>{' '}
        {message2 && <span className={'message2'}> {message2} </span>}
        {btn && (
          <button className="mydoh-button" {...btn}>
            {btn.text}
          </button>
        )}
        <MobileView>
          {mobileBtn && (
            <button className="mydoh-button" {...mobileBtn}>
              {mobileBtn.text}
            </button>
          )}
        </MobileView>
      </div>
      <img src={mydohLogoHorizontal} alt="" className="activation-logo__bottom" />
    </div>
  );
};

export default ActivateCard;
