import React from 'react';

import '../../App.scss';
import '../../css/common.scss';
import '../../css/fonts.scss';

const BackgroundWrapper = props => {
  return (
    <div className="App">
      <div className={props.cssClass}>{props.children}</div>
    </div>
  );
};

export default BackgroundWrapper;
