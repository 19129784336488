import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import ActivationContent from './ActivateAccount.content';
import ActivateCard from './ActivateCard';
import './ActivateAccount.scss';
import BackgroundWrapper from '../BackgroundWrapper/BackgroundWrapper';
import Header from '../../components/header/Header';
import ErrorCard from './ErrorCard';

const Activation = () => {
  const { search } = useLocation();
  const { token } = queryString.parse(search);
  const [isResent, setIsResent] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState({ title: '', message: '', isError: false });
  const { success, errors, logo, mobileBtn } = ActivationContent;
  useEffect(() => {
    (async function() {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_MYDOH_BE}/users/activate?token=${token}`,
        );
        if (result.status !== 200) {
          throw Error();
        }
        setState(success);
        setIsVerified(true);
        setIsSuccess(true);
      } catch (err) {
        const errorType = err.response?.data?.type;
        if (errorType && errors[errorType]) {
          setState(errors[errorType]);
          setIsVerified(true);
        } else {
          setState(errors.DEFAULT);
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [errors, success, token]);

  const handleResend = async () => {
    const result = await axios.post(
      `${process.env.REACT_APP_MYDOH_BE}/users/resend-activation-code`,
      { token },
    );
    if (result.status === 201) setIsResent(true);
  };

  const btn = {
    text: isResent ? 'Succesfully sent' : 'Resend Link',
    onClick: handleResend,
    disabled: isResent,
  };

  return (
    <BackgroundWrapper cssClass={`background bgform`}>
      <Header />
      {!isLoading && state.isError ? (
        <ErrorCard
          title={state.title}
          message={state.message}
          btn={!isVerified && !isLoading && btn}
        />
      ) : (
        <ActivateCard
          title={state.title}
          message={state.message}
          message2={state.message2}
          logo={logo}
          btn={!isVerified && !isLoading && btn}
          mobileBtn={isVerified && isSuccess && mobileBtn}
        />
      )}
    </BackgroundWrapper>
  );
};

export default Activation;
