import React from 'react';
import { useTranslation } from 'react-i18next';
import './Tooltip.scss';

const VALID_CHARS = '@$!%*#?&“\'"()+,-./:;<=>[]^_`{|}~';

const Tooltip = () => {
  const { t } = useTranslation('passwordReset');
  return (
    <div className="tooltip-wrap">
      <div className="tooltip-container">
        <div className="content">
          <span>{t('tooltip')}</span>
          <span>{VALID_CHARS}</span>
        </div>
      </div>
      <div className="arrow"></div>
    </div>
  );
};

export default Tooltip;
