import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import errorCloud from '../../assets/images/errorCloud.svg';
import FormCard from '../../components/card/FormCard';
import { ENGLISH_SUPPORT_EMAIL, FRENCH_SUPPORT_EMAIL } from '../../constants/general';
import i18n from '../../lang';
import './ErrorCard.scss';

const EmailLink = ({ children }) => {
  const userLanguage = i18n.language;
  return (
    <a href={`mailto:${userLanguage === 'en' ? ENGLISH_SUPPORT_EMAIL : FRENCH_SUPPORT_EMAIL}`}>
      {children}
    </a>
  );
};

const ErrorCard = () => {
  const { t } = useTranslation('unblockAccount');
  return (
    <FormCard>
      <div className="activation__error-content">
        <img src={errorCloud} alt="" className="error-img" />
        <p className="title">{t('defaultError.title')}</p>
        <p className="message">
          <Trans
            t={t}
            i18nKey="defaultError.message"
            components={{
              emailLink: <EmailLink />,
            }}
          />
        </p>
      </div>
    </FormCard>
  );
};

export default ErrorCard;
