import React from 'react';

import Card from '../../components/card/Card';
import AcceptInviteContent from './AcceptInvite.content';
import BackgroundWrapper from '../BackgroundWrapper/BackgroundWrapper';

const AcceptInvite = () => {
  return (
    <BackgroundWrapper cssClass="background img">
      <Card {...AcceptInviteContent} />
    </BackgroundWrapper>
  );
};

export default AcceptInvite;
