import * as Yup from 'yup';
import i18next from '../../lang';
const passwordValidationRegex = /^(?=.*[A-Z])(?=.*[@$!%*#?&“'"()+,-./:;<=>[\]^_`{|}~])(?=.*[0-9]).{8,}$/;

const minimumStringLengthValidator = value => value !== undefined && value.length >= 8;

const capitalLetterInStringValidator = value => value !== undefined && /[A-Z]/.test(value);

const numberInStringValidator = value => value !== undefined && /[0-9]/.test(value);

const specialCharacterInStringValidator = value =>
  value !== undefined && /[@$!%*#?&“'"()+,-./:;<=>[\]^_`{|}~]/.test(value);

const passwordValidation = () => {
  return Yup.string().matches(passwordValidationRegex, i18next.t('passwordReset:complexityError'));
};

export const PasswordInputValidationSchema = () => {
  return Yup.object().shape({
    password: passwordValidation(),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref('password')], i18next.t('passwordReset:matchingError'))
      .required(i18next.t('common:required')),
  });
};

export const passwordValidations = [
  { key: 'minChar', validator: minimumStringLengthValidator },
  { key: 'capChar', validator: capitalLetterInStringValidator },
  { key: 'numberChar', validator: numberInStringValidator },
  {
    key: 'specialChar',
    validator: specialCharacterInStringValidator,
  },
];
