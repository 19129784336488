import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './css/_mydoh-variables.scss';
import './App.scss';
import './css/common.scss';
import './css/fonts.scss';
import './css/onetrust.css';
import Routes from './Routes';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        closeOnClick={true}
        pauseOnHover={false}
        draggable={false}
        theme="colored"
        icon={false}
        closeButton={false}
      />
    </>
  );
}

export default App;
