import passwordResetImage from '../../assets/images/passwordReset.png';
import errorExclamation from '../../assets/images/errorExclamation.svg';
import React from 'react';
import iconsLargeFlag from '../../assets/images/iconsLargeFlag.svg';
const handleBackToApp = () => {
  const { REACT_APP_PREFIX } = process.env;
  window.location.href = `${REACT_APP_PREFIX}signin`;
};

export default {
  image: {
    source: passwordResetImage,
    width: 125,
    height: 125,
  },
  title: 'Create New Password',
  passwordLabel: 'New Password',
  confirmPasswordLabel: 'Re-enter New Password',
  placeholder: 'Enter your password here',
  button: 'Create New Password',
  errorImg: () => <img src={errorExclamation} className="error-exclamation image" alt="" />,
  errorTitle: 'Uh-oh',
  errorMsg: 'Looks like something went wrong. We were unable to change your password.',
  errorBtn: 'Try Again',
  successImg: () => <img src={iconsLargeFlag} className="success-flag image" alt="" />,
  successTitle: 'Success',
  successMsg: 'You’ve reset your password. Please log in to the app to continue.',
  successBtn: 'Log In to Mydoh',
  passwordResetV2: {
    onClick: handleBackToApp,
  },
};
