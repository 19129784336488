import axios from 'axios';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Header from '../../components/header/Header';
import BackgroundWrapper from '../BackgroundWrapper/BackgroundWrapper';
import ErrorCard from './ErrorCard';
import UnblockAccountContent from './UnblockAccount.content';
import './UnblockAccount.scss';
import UnblockCard from './UnblockCard';
import { WithTranslation } from '../../lang/WithTranslation.hoc';

const UnblockAccount = () => {
  const { t } = useTranslation('unblockAccount');
  const { search } = useLocation();
  const { verificationCode, memberId } = queryString.parse(search);
  const [isVerified, setIsVerified] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState({ title: '', message: '', button: '', isError: false });
  const { logo, mobileBtn } = UnblockAccountContent;

  useEffect(() => {
    (async function() {
      const successCopy = t('success', {
        returnObjects: true,
      });

      try {
        const result = await axios.patch(
          `${process.env.REACT_APP_MYDOH_BE}/device-verification/confirm`,
          {
            verificationCode,
            memberId,
          },
        );
        if (result.status !== 200) {
          throw Error();
        }
        setState(successCopy);
        setIsVerified(true);
        setIsSuccess(true);
      } catch (err) {
        //Show appropriate message when the verification code has expired (it is valid for 24 hours)
        if (err.response.status === 403) {
          setState({
            title: t('linkExpired.title'),
            message: t('linkExpired.message'),
            button: t('linkExpired.button'),
          });
          setIsVerified(true);
          setIsSuccess(true);
        } else {
          setState({
            isError: true,
          });
        }
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, [verificationCode, memberId]);

  return (
    <BackgroundWrapper cssClass={`background bgform`}>
      <Header />
      {!isLoading && state.isError ? (
        <ErrorCard />
      ) : (
        <UnblockCard
          title={state.title}
          message={state.message}
          message2={state.message2}
          logo={logo}
          mobileBtn={
            isVerified &&
            isSuccess && {
              onClick: mobileBtn.onClick,
              text: state.button,
            }
          }
        />
      )}
    </BackgroundWrapper>
  );
};

export default WithTranslation(UnblockAccount);
