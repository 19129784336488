import * as Yup from 'yup';
import * as linkify from 'linkifyjs';
import { ProfanityService } from '@rbcv/mydoh-common/';
import i18next from '../../lang';

const profanityService = new ProfanityService();
export const ReceiveMoneyFormValidation = Yup.object({
  amount: Yup.number()
    .typeError(`${i18next.t('receiveMoney:receiveMoneyForm.validations.amountTypeError')}`)
    .required(`${i18next.t('receiveMoney:receiveMoneyForm.validations.required')}`)
    .min(5, `${i18next.t('receiveMoney:receiveMoneyForm.validations.minimumAmount')}`)
    .max(500, `${i18next.t('receiveMoney:receiveMoneyForm.validations.maximumAmount')}`),
  firstName: Yup.string()
    .typeError(`${i18next.t('receiveMoney:receiveMoneyForm.validations.nameTypeError')}`)
    .required(`${i18next.t('receiveMoney:receiveMoneyForm.validations.required')}`)
    .strict()
    .trim(`${i18next.t('receiveMoney:receiveMoneyForm.validations.noSpaces')}`)
    .min(2, `${i18next.t('receiveMoney:receiveMoneyForm.validations.minimumCharacters')}`)
    .max(20, `${i18next.t('receiveMoney:receiveMoneyForm.validations.maximumCharacters')}`)
    .matches(
      /^([a-zA-Z])(([a-zA-Z])+([- ])?)*([a-zA-Z])$/,
      `${i18next.t('receiveMoney:receiveMoneyForm.validations.invalidCharacter')}`,
    )
    .test(
      'hasBadWords',
      `${i18next.t('receiveMoney:receiveMoneyForm.validations.hasBadWords')}`,
      value => {
        return !profanityService.isProfane(value);
      },
    ),
  lastName: Yup.string()
    .typeError(`${i18next.t('receiveMoney:receiveMoneyForm.validations.nameTypeError')}`)
    .required(`${i18next.t('receiveMoney:receiveMoneyForm.validations.required')}`)
    .strict()
    .trim(`${i18next.t('receiveMoney:receiveMoneyForm.validations.noSpaces')}`)
    .min(2, `${i18next.t('receiveMoney:receiveMoneyForm.validations.minimumCharacters')}`)
    .max(20, `${i18next.t('receiveMoney:receiveMoneyForm.validations.maximumCharacters')}`)
    .matches(
      /^([a-zA-Z])(([a-zA-Z])+([- ])?)*([a-zA-Z])$/,
      `${i18next.t('receiveMoney:receiveMoneyForm.validations.invalidCharacter')}`,
    )
    .test(
      'hasBadWords',
      `${i18next.t('receiveMoney:receiveMoneyForm.validations.hasBadWords')}`,
      value => {
        return !profanityService.isProfane(value);
      },
    ),
  email: Yup.string()
    .email(`${i18next.t('receiveMoney:receiveMoneyForm.validations.checkEmail')}`)
    .required(`${i18next.t('receiveMoney:receiveMoneyForm.validations.required')}`)
    .trim(`${i18next.t('receiveMoney:receiveMoneyForm.validations.noSpaces')}`),
  note: Yup.string()
    .test('hasURLs', `${i18next.t('receiveMoney:receiveMoneyForm.validations.hasUrls')}`, value => {
      return !linkify.find(value || '')?.filter(found => found.type === 'url')?.length;
    })
    .test(
      'hasBadWords',
      `${i18next.t('receiveMoney:receiveMoneyForm.validations.hasBadWords')}`,
      value => {
        return !profanityService.isProfane(value);
      },
    ),
});
