import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import SvgService from '../../services/SvgService';
import './ReceiveMoneyError.scss';
import './ReceiveMoneyForm.scss';
import { ReceiveMoneyErrorType } from './ReceiveMoneyWizard';
import { WithTranslation } from '../../lang/WithTranslation.hoc';
import { useTranslation, Trans } from 'react-i18next';

type ReceiveMoneyErrorProps = {
  username?: {
    firstName: string;
  };
  errorType: ReceiveMoneyErrorType;
  payeeId?: string;
};
const ReceiveMoneyError = ({ username, errorType, payeeId }: ReceiveMoneyErrorProps) => {
  const { t } = useTranslation('receiveMoney');

  const getPaymentIssueCopy = () => {
    switch (errorType) {
      case 'ACCOUNT_LIMIT_ISSUE':
        return {
          title: 'receiveMoney:receiveMoneyError.accountLimitIssue.title',
          body: 'receiveMoney:receiveMoneyError.accountLimitIssue.body',
        };

      case 'SERVICE_OUTAGE':
        return {
          title: 'receiveMoney:receiveMoneyError.serviceOutage.title',
          body: 'receiveMoney:receiveMoneyError.serviceOutage.body',
        };
      default:
        return {
          title: 'receiveMoney:receiveMoneyError.paymentIssue.title',
          body: 'receiveMoney:receiveMoneyError.paymentIssue.body',
        };
    }
  };

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        payeeId: payeeId,
        event: 'error',
        category: 'receive payment link flow',
        action: 'error occured',
        label: errorType,
      },
    };
    //sends tracking data to Google Tags Manager
    TagManager.dataLayer(tagManagerArgs);
  }, [errorType, payeeId]);

  return (
    <div className="form-width error-container">
      <img src={SvgService.paymentIssue} alt="Error Processing Payment" />
      <p className="error-title">{t(getPaymentIssueCopy().title)}</p>
      <p className="error-body-text">
        <Trans
          i18nKey={getPaymentIssueCopy().body}
          values={{ firstName: username?.firstName }}
          components={{
            mail: (
              // need to disable because this component just needs the tag without content
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a style={{ textDecoration: 'underline' }} href="mailto:support@mydoh.ca" />
            ),
          }}
        />
      </p>
    </div>
  );
};

export default WithTranslation(ReceiveMoneyError);
