import React from 'react';
import SvgService from '../../services/SvgService';
import './BrandingHeader.scss';

type BrandingHeaderProps = {
  isSuccessScreen?: boolean;
};
const BrandingHeader = ({ isSuccessScreen }: BrandingHeaderProps) => {
  return (
    <div className={`branding-header-container ${isSuccessScreen && 'success-screen'}`}>
      <img
        src={SvgService.mydohLogoHorizontal}
        alt="Mydoh"
        className={`logo-img ${isSuccessScreen ? 'inverted' : ''}`}
      />
    </div>
  );
};

export default BrandingHeader;
