import 'branch-sdk';
import { useCallback, useEffect } from 'react';
import { Route, Switch, useHistory, withRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import AcceptInvite from './views/AcceptInvite';
import ActivateAccount from './views/ActivateAccount';
import ApplicationRequired from './views/ApplicationRequired';
import UnblockAccount from './views/UnblockAccount';
import PasswordReset from './views/PasswordReset';
import Moneris from './views/Moneris';
import ReceiveMoneyWizard from './views/ChildReceivePayments/ReceiveMoneyWizard.tsx';
import { ampli } from './ampli';

const allRoutes = [
  AcceptInvite,
  ActivateAccount,
  PasswordReset,
  ApplicationRequired,
  UnblockAccount,
  Moneris,
];

const Routes = ({ location }) => {
  const history = useHistory();

  const tagManagerArgs = {
    gtmId: 'GTM-MNNWGJP', //prodId. Marketing says prod and dev are shared environments.
    dataLayer: {
      auth: process.env.REACT_APP_GTM_ENV_KEY, // no key is required for production
      userProject: window?.location?.href.split('/')?.[2], // this just gets base url without http or any params, will be useful in determining prod (accounts.mydoh.com or mydoh.me) and non prod (localhost and accounts.stg.mydoh-dev.com)
    },
  };

  const handleBranch = useCallback(
    (err, data) => {
      if (err) {
        console.error(err);
        return;
      }

      // Stop branching if not main route (this allows refresh of current page)
      if (location.pathname !== '/') {
        return;
      }

      const { routeName } = data.data_parsed;
      switch (routeName) {
        case 'ChildAcceptInvitationScreen':
          history.replace(AcceptInvite.path);
          break;
        case 'ResetPasswordScreen':
          history.replace(PasswordReset.path);
          break;
        default:
          window.location.href = process.env.REACT_APP_MYDOH_WEB;
      }
    },
    [history, location.pathname],
  );

  useEffect(() => {
    // Initialize Amplitude Analytics
    ampli.load({
      client: {
        apiKey: process.env.REACT_APP_AMPLITUDE_API_KEY,
        // defaultTracking is set to true by default and triggers page view events automatically unless it is set to false explicitly
        configuration: { defaultTracking: false },
      },
    });

    //Initialize Google Tag Manager
    try {
      TagManager.initialize(tagManagerArgs);
      console.log('Initialized google tags manager');
    } catch (error) {
      console.log('Unable to initialize google tags manager');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    branch.init(process.env.REACT_APP_BRANCH_KEY, handleBranch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleBranch]);

  const handleRedirectToMydoh = () => {
    window.location.href = process.env.REACT_APP_MYDOH_WEB;
  };

  return (
    <Switch>
      {allRoutes
        .filter(route => route.component)
        .map(route => (
          <Route exact={true} {...route} />
        ))}
      <Route exact path="/:payeeId/:payerId" component={ReceiveMoneyWizard} />
      <Route path="/:payeeId" component={ReceiveMoneyWizard} />
      <Route exact path="/" component={null} />
      <Route exact path="*" component={handleRedirectToMydoh} />
    </Switch>
  );
};

export default withRouter(Routes);
