import axios from 'axios';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import FormCard from '../../components/card/FormCard';
import Header from '../../components/header/Header';
import Modal from '../../components/modal/Modal';
import { WithTranslation } from '../../lang/WithTranslation.hoc';
import BackgroundWrapper from '../BackgroundWrapper/BackgroundWrapper';
import PasswordResetContent from './PasswordReset.content';
import PasswordResetForm from './PasswordResetForm';
import './PasswordResetForm.scss';
import InfoCard from '../../components/card/InfoCard';

const PasswordReset = () => {
  const { t } = useTranslation('passwordReset');

  const { search } = useLocation();
  const { token } = queryString.parse(search);
  const [isLoading, setIsLoading] = useState(true);

  const [state, setState] = useState({
    type: undefined,
    title: undefined,
    message: undefined,
    btn: undefined,
    Image: undefined,
    isError: false,
  });
  const { REACT_APP_PREFIX } = process.env;
  const linkExpiredType = 'Link already expired';

  const closeModal = () => {
    setState({ title: undefined, message: undefined, btn: undefined, isError: undefined });
    if (isMobile && !state.isError) {
      window.location.href = REACT_APP_PREFIX;
    }
  };

  useEffect(() => {
    (async function(token, t) {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_MYDOH_BE}/users/forgot-password/link-expiry/${token}`,
        );
        if (result.status !== 200) {
          throw Error();
        }
      } catch (err) {
        setState({
          isError: true,
          type: linkExpiredType,
          title: t('linkExpiredTitle'),
          message: t('linkExpiredMessage'),
          btn: t('linkExpiredButton'),
        });
      } finally {
        setIsLoading(false);
      }
    })(token, t);
  }, [token, t]);

  const handleSubmit = async values => {
    try {
      await axios.post(`${process.env.REACT_APP_MYDOH_BE}/users/forgot-password/recovery`, {
        recoveryToken: token,
        newPassword: values.password,
      });
      setState({
        title: t('successTitle'),
        message: t('successMsg'),
        btn: t('successBtn'),
        Image: PasswordResetContent.successImg,
        isError: false,
      });
    } catch (error) {
      setState({
        title: t('errorTitle'),
        message: t('errorMsg'),
        btn: t('errorBtn'),
        Image: PasswordResetContent.errorImg,
        isError: true,
      });
    }
  };

  return (
    <>
      {state.title && state.type === linkExpiredType && !isLoading && (
        <BackgroundWrapper cssClass={`background bgform`}>
          <Header />
          <InfoCard
            title={state.title}
            message={state.message}
            button={state.btn}
            {...PasswordResetContent.passwordResetV2}
          />
        </BackgroundWrapper>
      )}
      {state.title && state.type !== linkExpiredType && (
        <Modal
          Image={state.Image}
          title={state.title}
          message={state.message}
          btnText={state.btn}
          btnOnClick={closeModal}
          isError={state.isError}
        />
      )}
      <BackgroundWrapper cssClass={`background bgform ${state.isError && 'blurred'}`}>
        <Header />
        {!state.title && !isLoading && (
          <FormCard>
            <span className="form-title">{t('title')}</span>
            <PasswordResetForm handleSubmit={handleSubmit} />
          </FormCard>
        )}
      </BackgroundWrapper>
    </>
  );
};

export default WithTranslation(PasswordReset);
