import React from 'react';
import './Moneris.scss';
import { useTranslation } from 'react-i18next';

const makeQueryString = paramsObject =>
  Object.entries(paramsObject)
    .map(([key, val]) => `${key}=${val}`)
    .join('&');

const MOBILE_MONERIS_OPTIONS = {
  css_body: `
    font-family:'Inter',sans-serif;
    display: block;
    `,
  css_textbox_pan: `
    flex: 2 1 100%;
    margin-top: 5px;
    margin-bottom: 20px;
    padding: 10px;
    height: fit-content;
    width: 100%;
    font-size:14px;
    border-radius: 8px;
    border: 1px solid;
    height: 40px;
    placeholder: 'Card number'
  `,
  css_textbox_exp: `
    flex: 2 1 100%;
    padding: 10px;
    height: fit-content;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 20px;
    font-size:14px;
    border-radius: 8px;
    border: 1px solid;
    height: 40px;
  `,
  css_textbox_cvd: `
    flex: 2 1 100%;
    padding: 10px;
    height: fit-content;
    width: 100%;
    margin-top: 5px;
    margin-bottom:20px;
    font-size:14px;
    border-radius: 8px;
    border: 1px solid;
    height: 40px;
  `,
  css_input_label: `
    font-family: Inter, sans-serif;
    font-size: 14px;
    height: fit-content;
    font-weight: 700;
  `,

  // css_label_pan:
  // css_label_exp:
  // css_label_cvd
};

const MonerisForm = ({ monerisRef, className }) => {
  const { t } = useTranslation('monerisContent');
  const SHARED_MONERIS_OPTIONS = {
    id: process.env.REACT_APP_HOSTED_TOKENIZATION_ID,
    pmmsg: 'true', // Forces form to only accept message of 'tokenize'.
    display_labels: '1',

    pan_label: t('form.panLabel'),
    exp_label: t('form.expLabel'),
    cvd_label: t('form.cvdLabel'),
    enable_cvd: '1',
    enable_exp: '1',
    css_textbox: `
      border: 1px solid rgb(142,142,142);
      `,
  };
  let monerisSrc = { ...SHARED_MONERIS_OPTIONS, ...MOBILE_MONERIS_OPTIONS };
  return (
    <>
      <div id="monerisResponse"></div>
      <iframe
        className={className}
        ref={monerisRef}
        id="monerisFrame"
        title="moneris"
        width="100%"
        src={`${process.env.REACT_APP_HOSTED_TOKENIZATION_URL}?${makeQueryString(monerisSrc)}`}
      />
    </>
  );
};
export default MonerisForm;
