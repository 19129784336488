import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useFormik } from 'formik';

import { ReceiveMoneyWizardState, ReceiveMoneyWizardStep } from './ReceiveMoneyWizard';
import UsernameHeader from './components/UsernameHeader/UsernameHeader';
import MydohInput from '../../components/mydohInput/MydohInput';
import { ReceiveMoneyFormValidation } from './ReceiveMoneyForm.validation';
import './ReceiveMoneyForm.scss';
import Button from '../../components/button/Button';
import MessageTextArea from './components/MessageTextArea/MessageTextArea';
import { useTranslation } from 'react-i18next';
import { WithTranslation } from '../../lang/WithTranslation.hoc';

type ReceiveMoneyFormProps = {
  payeeId: string;
  form: ReceiveMoneyWizardState['RECEIVE_MONEY_FORM'];
  onSubmit: (
    index: ReceiveMoneyWizardStep,
    { amount, firstName, lastName, email, note }: ReceiveMoneyWizardState['RECEIVE_MONEY_FORM'],
  ) => void;
};

const ReceiveMoneyForm = ({ payeeId, form, onSubmit }: ReceiveMoneyFormProps) => {
  const { t } = useTranslation('receiveMoney');
  const formik = useFormik({
    initialValues: {
      username: {
        firstName: '',
        lastNameInitial: '',
      },
      amount: '',
      firstName: form.firstName,
      lastName: form.lastName,
      email: form.email,
      note: form.note,
    },
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: ReceiveMoneyFormValidation,
    onSubmit: () => {},
  });

  const { values, errors, handleChange, touched, setFieldValue } = formik;

  const handleSubmit = (event: Event) => {
    event.preventDefault();
    onSubmit('MONERIS_FORM', { ...values });
  };

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        payeeId: payeeId,
        event: 'virtual_pageview',
        virtual_page_url: 'vpv/receive-payment-link-flow/step1/send-money-to',
        virtual_page_title: 'Send money to',
        pageContentType: 'receive payment link flow',
      },
    };

    setFieldValue('username', {
      firstName: form.username.firstName,
      lastNameInitial: form.username.lastNameInitial,
    });

    //sends tracking data to Google Tags Manager
    TagManager.dataLayer(tagManagerArgs);
  }, [form, setFieldValue, payeeId]);

  return (
    <form onSubmit={handleSubmit} className="receive-money-form form-width">
      <UsernameHeader
        username={`${values.username.firstName} ${values.username.lastNameInitial}.`}
      />
      <h2 className="heading-label amount-label">{t('receiveMoneyForm.amount')}</h2>
      <MydohInput
        name="amount"
        placeholder={t('receiveMoneyForm.amountPlaceholder')}
        type="number"
        value={values.amount}
        onChange={handleChange}
        onBlur={(e: Event) => {
          formik.setFieldValue('amount', values.amount);
          formik.handleBlur(e);
        }}
        isTouched={touched.amount}
        error={errors.amount}
      />
      <h2 className="heading-label sender-label">{t('receiveMoneyForm.sender')}</h2>
      <div className="name-container">
        <MydohInput
          type="text"
          placeholder={t('receiveMoneyForm.firstName')}
          name="firstName"
          value={values.firstName}
          onChange={handleChange}
          onBlur={(e: Event) => {
            formik.setFieldValue('firstName', values.firstName?.trim());
            formik.handleBlur(e);
          }}
          isTouched={touched.firstName}
          error={errors.firstName}
        />
        <MydohInput
          type="text"
          placeholder={t('receiveMoneyForm.lastName')}
          name="lastName"
          value={values.lastName}
          onChange={handleChange}
          onBlur={(e: Event) => {
            formik.setFieldValue('lastName', values.lastName?.trim());
            formik.handleBlur(e);
          }}
          isTouched={touched.lastName}
          error={errors.lastName}
        />
      </div>
      <MydohInput
        type="email"
        placeholder={t('receiveMoneyForm.emailAddress')}
        name="email"
        value={values.email}
        onChange={handleChange}
        onBlur={(e: Event) => {
          formik.setFieldValue('email', values.email?.trim());
          formik.handleBlur(e);
        }}
        isTouched={touched.email}
        error={errors.email}
      />
      <MessageTextArea
        placeholder={t('receiveMoneyForm.personalNote')}
        name="note"
        value={values.note}
        onChange={handleChange}
        onBlur={(e: Event) => {
          formik.setFieldValue('note', values.note?.trim());
          formik.handleBlur(e);
        }}
        isTouched={touched.note}
        error={errors.note}
      />
      <p className="message-disclaimer">{t('receiveMoneyForm.personalNoteDisclaimer')}</p>
      <Button
        className="form-button"
        type={'submit'}
        value="Submit"
        disabled={!!Object.values(errors).length}
        text={t('receiveMoneyForm.payWithDebitCard')}
      />
    </form>
  );
};

export default WithTranslation(ReceiveMoneyForm);
