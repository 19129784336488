import React, { useEffect } from 'react';
import { cavvAndVerification } from '../../services/childReceivePayments.actions';
import { useHistory } from 'react-router';
import MydohLoader from './components/MydohLoader/MydohLoader';

const ReceiveMoneyMonerisChallenge = ({ payeeId, payerId, onSubmit }) => {
  const history = useHistory();

  useEffect(() => {
    history.push(`/${payeeId}`);
  }, [history, payeeId]);

  useEffect(() => {
    if (!payerId) {
      return;
    }
    // if cavvAndVerification is successful, populate state with extracted form data and navigate to review
    // else navigate to error
    cavvAndVerification({ payerId })
      .then(response => {
        const { amount, cardExpiryDate, maskedPan, message, payee, payer } = response;
        onSubmit('RECEIVE_MONEY_CONFIRMATION', {
          username: {
            firstName: payee?.firstName,
            lastNameInitial: payee?.lastNameInitial,
          },
          amount,
          firstName: payer?.firstName,
          lastName: payer?.lastName,
          email: payer?.email,
          note: message,

          lastFourDigits: maskedPan,
          expiryDate: cardExpiryDate,
          payerId,
        });
      })
      .catch(error => {
        if (error?.response?.status === 503) {
          onSubmit('RECEIVE_MONEY_ERROR', 'SERVICE_OUTAGE');
        }
        onSubmit('RECEIVE_MONEY_ERROR', 'PAYMENT_ISSUE');
      });
  }, [payerId, payeeId, onSubmit]);

  return <MydohLoader />;
};

export default ReceiveMoneyMonerisChallenge;
