import React from 'react';
import { MobileView } from 'react-device-detect';
import './InfoCard.scss';

const InfoCard = props => {
  return (
    <div className={'info-card'}>
      <p className={'title'}>{props.title}</p>
      <p className={'message'}>{props.message}</p>
      <MobileView style={{ width: '100%' }}>
        <button className="mydoh-button" {...props}>
          {props.button}
        </button>
      </MobileView>
    </div>
  );
};

export default InfoCard;
