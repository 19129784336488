import React, { useCallback, useEffect, useRef } from 'react';
import { MobileView } from 'react-device-detect';
import Button from '../button/Button';
import './Modal.scss';

const Modal = ({ imgScr, Image, alt, title, message, btnText, btnOnClick, isError }) => {
  const outerNode = useRef();

  const closeModal = useCallback(() => {
    btnOnClick();
  }, [btnOnClick]);

  useEffect(() => {
    const handleClickOutside = e => {
      if (outerNode.current?.contains(e.target)) {
        closeModal();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeModal]);

  return (
    <div className="modal">
      {Image ? <Image /> : <img src={imgScr} alt={alt} className="image" />}
      <span className="modal__title">{title}</span>
      <span className="modal__message">{message}</span>
      <MobileView style={{ width: '100%' }}>
        {!isError && <Button type="button" disabled={false} text={btnText} onClick={closeModal} />}
      </MobileView>

      {isError && <Button type="button" disabled={false} text={btnText} onClick={closeModal} />}
    </div>
  );
};

export default Modal;
