import * as React from 'react';
import { WithTranslation } from '../../../../lang/WithTranslation.hoc.jsx';
import './FirstTimeMydoherBanner.scss';
import SvgService from '../../../../services/SvgService';
import { useTranslation } from 'react-i18next';

const FirstTimeMydoherBanner = () => {
  const { t } = useTranslation('receiveMoney');
  return (
    <a href={`${t('firstTimeMydoherBanner.url')}`} target="_blank" rel="noreferrer">
      <div className="banner-container">
        <img src={SvgService.mydohDebitCard} alt="Mydoh physical card" />
        <div className="banner-text-container">
          <p className="banner-heading">{t('firstTimeMydoherBanner.heading')}</p>
          <p className="banner-body-text">{t('firstTimeMydoherBanner.body')}</p>
        </div>
      </div>
    </a>
  );
};

export default WithTranslation(FirstTimeMydoherBanner);
