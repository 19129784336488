export default {
  btn: 'Continuer',
  form: {
    panLabel: 'Numéro de carte',
    expLabel: 'Date d’expiration MMAA',
    cvdLabel: 'CVC',
    cardHolderName: 'Nom du titulaire de la carte',
    address: 'Adresse',
    city: 'Ville',
    province: 'Province',
    postalCode: 'Code postal',
  },
  formValidation: {
    required: 'Obligatoire',
    minimumCharacters: 'Doit avoir au moins {{minLength}} lettres',
    maximumCharacters: 'Doit contenir au maximum {{maxLength}} lettres',
    invalidCharacter: "Notre processeur de paiement n'autorise pas les caractères spéciaux",
  },
  error: {
    cardMsg: `Veuillez s'il vous plaît vérifier les informations suivantes et réessayer :`,
    genericMsg:
      'Quelque chose s’est mal passé, veuillez examiner les informations de votre carte et réessayer.',
    cardNumberMsg: 'Numéro de carte',
    cardExpMsg: ' Date d’expiration',
    cardCVDMsg: 'CVC',
    genericSingleErrorMsg: `Veuillez s'il vous plaît vérifier que vous ayez le bon {{erroredField}} et réessayer.`,
  },
};
