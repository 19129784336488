import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import ReceiveMoneyForm from './ReceiveMoneyForm';
import MonerisWebForm from './MonerisWebForm';
import ReceiveMoneyConfirmation from './ReceiveMoneyConfirmation';
import { isPaymentAllowedForPayee } from '../../services/childReceivePayments.actions';
import ReceiveMoneySuccess from './ReceiveMoneySuccess';
import ReceiveMoneyError from './ReceiveMoneyError';
import BrandingHeader from '../../components/brandingHeader/BrandingHeader';
import ReceiveMoneyMonerisChallenge from './ReceiveMoneyMonerisChallenge';
import LegalFooter from './components/LegalFooter/LegalFooter';
import BackgroundWrapper from '../BackgroundWrapper/BackgroundWrapper';
import ReceiveMoneyLoader from './ReceiveMoneyLoader';

export const receiveMoneyWizardSteps = [
  'RECEIVE_MONEY_FORM',
  'MONERIS_FORM',
  'RECEIVE_MONEY_MONERIS_CHALLENGE',
  'RECEIVE_MONEY_CONFIRMATION',
  'RECEIVE_MONEY_SUCCESS',
  'RECEIVE_MONEY_ERROR',
  'RECEIVE_MONEY_LOADER',
] as const;

export type ReceiveMoneyWizardStep = typeof receiveMoneyWizardSteps[number];
/* ReceiveMoneyErrorType
ACCOUNT_LIMIT_ISSUE - /check-payment-allowed fails
SERVICE_OUTAGE - some part of the service is off
PAYMENT_ISSUE - an endpoint has failed
*/
export type ReceiveMoneyErrorType =
  | 'ACCOUNT_LIMIT_ISSUE'
  | 'SERVICE_OUTAGE'
  | 'PAYMENT_ISSUE'
  | undefined;

export type ReceiveMoneyWizardState = {
  index: ReceiveMoneyWizardStep;
  errorType: ReceiveMoneyErrorType;
  ['RECEIVE_MONEY_FORM']: {
    username: {
      firstName: string;
      lastNameInitial: string;
    };
    amount: string;
    firstName: string;
    lastName: string;
    email: string;
    note: string;
  };
  ['MONERIS_FORM']: {
    cardholderName: string;
    address: string;
    city: string;
    province: string;
    postalCode: string;
    dataKey: string;
    lastFourDigits: string;
    payerId: string;
    expiryDate: string;
    challengeUrl: string;
    challengeData: string;
  };
};

export type ReceiveMoneyFormParams = {
  payerId: string;
  payeeId: string;
};
const ReceiveMoneyWizard = () => {
  const { payeeId, payerId } = useParams<ReceiveMoneyFormParams>();
  const [form, setFormState] = useState<ReceiveMoneyWizardState>({
    //TODO: initial index should be loading page to prevent jumpiness from form to error
    index: 'RECEIVE_MONEY_LOADER',
    errorType: undefined,
    RECEIVE_MONEY_FORM: {
      username: {
        firstName: '',
        lastNameInitial: '',
      },
      amount: '',
      firstName: '',
      lastName: '',
      email: '',
      note: '',
    },
    MONERIS_FORM: {
      cardholderName: '',
      address: '',
      city: '',
      province: '',
      postalCode: '',
      dataKey: '',
      lastFourDigits: '',
      payerId,
      expiryDate: '',
      challengeData: '',
      challengeUrl: '',
    },
  });

  useEffect(() => {
    isPaymentAllowedForPayee(payeeId)
      .then(({ isAllowed, name }) => {
        isAllowed && name
          ? setFormState({
              ...form,
              RECEIVE_MONEY_FORM: {
                username: name,
                amount: '',
                firstName: '',
                lastName: '',
                email: '',
                note: '',
              },
              index: payerId ? 'RECEIVE_MONEY_MONERIS_CHALLENGE' : 'RECEIVE_MONEY_FORM',
            })
          : setFormState({
              ...form,
              index: 'RECEIVE_MONEY_ERROR',
              errorType: name ? 'ACCOUNT_LIMIT_ISSUE' : 'PAYMENT_ISSUE',
              RECEIVE_MONEY_FORM: {
                username: name || { firstName: '', lastNameInitial: '' },
              },
            });
      })
      .catch(err => {
        setFormState({
          ...form,
          index: 'RECEIVE_MONEY_ERROR',
          errorType: 'SERVICE_OUTAGE',
        });
      });
    //hook only needs to run once. If you add 'form' to the dependency array, an infinite render cycle occurs
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payeeId]);

  const onReceiveMoneyFormSubmit = (
    index: ReceiveMoneyWizardStep,
    {
      username,
      amount,
      firstName,
      lastName,
      email,
      note,
    }: ReceiveMoneyWizardState['RECEIVE_MONEY_FORM'],
  ): void =>
    setFormState({
      ...form,
      index,
      RECEIVE_MONEY_FORM: {
        username,
        amount,
        firstName,
        lastName,
        email,
        note,
      },
    });

  const onMonerisFormSubmit = (
    index: ReceiveMoneyWizardStep,
    {
      cardholderName,
      address,
      city,
      province,
      postalCode,
      dataKey,
      lastFourDigits,
      payerId,
      expiryDate,
      challengeData,
      challengeUrl,
    }: ReceiveMoneyWizardState['MONERIS_FORM'],
    errorType?: ReceiveMoneyErrorType,
  ): void => {
    setFormState({
      ...form,
      index,
      errorType,
      MONERIS_FORM: {
        cardholderName,
        address,
        city,
        province,
        postalCode,
        dataKey,
        lastFourDigits,
        payerId,
        expiryDate,
        challengeData,
        challengeUrl,
      },
    });
  };

  const onReceiveMonerisChallengeSubmit = (
    index: ReceiveMoneyWizardStep,
    {
      username,
      amount,
      firstName,
      lastName,
      email,
      note,
      lastFourDigits,
      expiryDate,
    }: ReceiveMoneyWizardState['RECEIVE_MONEY_FORM'] & {
      lastFourDigits: string;
      expiryDate: string;
    },
    errorType?: ReceiveMoneyErrorType,
  ) =>
    setFormState({
      ...form,
      index,
      errorType,
      RECEIVE_MONEY_FORM: {
        username,
        amount,
        firstName,
        lastName,
        email,
        note,
      },
      MONERIS_FORM: {
        lastFourDigits,
        expiryDate,
        payerId,
      } as any,
    });

  const onReceiveMoneyConfirmationSubmit = (
    index: ReceiveMoneyWizardStep,
    errorType?: ReceiveMoneyErrorType,
  ) =>
    setFormState({
      ...form,
      index,
      RECEIVE_MONEY_FORM: {
        ...form.RECEIVE_MONEY_FORM,
        amount: '',
      },
      MONERIS_FORM: {
        ...form.MONERIS_FORM,
        dataKey: '',
        expiryDate: '',
        lastFourDigits: '',
        payerId: '',
      },
      errorType,
    });

  const renderStep = () => {
    const { index } = form;
    switch (index) {
      case 'RECEIVE_MONEY_FORM':
        return (
          <ReceiveMoneyForm
            payeeId={payeeId}
            form={form.RECEIVE_MONEY_FORM}
            onSubmit={onReceiveMoneyFormSubmit}
          />
        );
      case 'MONERIS_FORM':
        return (
          <MonerisWebForm
            payeeId={payeeId}
            receiveMoneyForm={form}
            onSubmit={onMonerisFormSubmit}
          />
        );

      case 'RECEIVE_MONEY_MONERIS_CHALLENGE':
        return (
          <ReceiveMoneyMonerisChallenge
            payeeId={payeeId}
            payerId={payerId}
            onSubmit={onReceiveMonerisChallengeSubmit}
          />
        );
      case 'RECEIVE_MONEY_CONFIRMATION':
        return (
          <ReceiveMoneyConfirmation
            payeeId={payeeId}
            receiveMoneyForm={form}
            onSubmit={onReceiveMoneyConfirmationSubmit}
          />
        );
      case 'RECEIVE_MONEY_SUCCESS':
        return (
          <ReceiveMoneySuccess payeeId={payeeId} username={form.RECEIVE_MONEY_FORM.username} />
        );
      case 'RECEIVE_MONEY_ERROR':
        return (
          <ReceiveMoneyError
            username={form.RECEIVE_MONEY_FORM.username}
            errorType={form?.errorType}
            payeeId={payeeId}
          />
        );
      case 'RECEIVE_MONEY_LOADER':
        return <ReceiveMoneyLoader />;
    }
  };

  return (
    <>
      <BrandingHeader isSuccessScreen={form.index === 'RECEIVE_MONEY_SUCCESS'} />
      <BackgroundWrapper cssClass={`background bgform`}>
        {renderStep()}
        {![
          'RECEIVE_MONEY_ERROR',
          'RECEIVE_MONEY_MONERIS_CHALLENGE',
          'RECEIVE_MONEY_LOADER',
        ].includes(form.index) && (
          <LegalFooter isSuccessScreen={form.index === 'RECEIVE_MONEY_SUCCESS'} />
        )}
      </BackgroundWrapper>
    </>
  );
};

export default ReceiveMoneyWizard;
