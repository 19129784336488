import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
function useMonerisForm(monerisRef, handleMessages) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const cardNumberCode = '943';
  const cardExpCode = '944';
  const cardCVDCode = '945';
  const { t } = useTranslation('monerisContent');
  const clearErrors = () => {
    setErrors([]);
  };

  const cancelLoading = () => {
    setLoading(false);
  };

  const doMonerisSubmit = () => {
    if (monerisRef.current && monerisRef.current.contentWindow) {
      monerisRef.current.contentWindow.postMessage(
        'tokenize',
        process.env.REACT_APP_HOSTED_TOKENIZATION_URL,
      );
      return false;
    } else {
      return;
    }
  };

  const handleFormSubmit = () => {
    setLoading(true);
    clearErrors();
    doMonerisSubmit();
  };

  const handleVaultError = responseCode => {
    const allErrors = [];
    responseCode.forEach(code => {
      switch (code) {
        case cardNumberCode:
          allErrors.push(t('error.cardNumberMsg'));
          cancelLoading();
          break;
        case cardExpCode:
          allErrors.push(t('error.cardExpMsg'));
          cancelLoading();
          break;
        case cardCVDCode:
          allErrors.push(t('error.cardCVDMsg'));
          cancelLoading();
          break;
        default:
          allErrors.push(t('error.genericMsg'));
          cancelLoading();
          break;
      }
    });
    if (allErrors.length > 0) {
      setErrors(err => [...err, ...allErrors]);
    }
  };

  const respMsg = React.useCallback(
    e => {
      if (!e.data || (e.data.source && e.data.source.includes('react-devtools'))) {
        return;
      }
      if (e.data.type === 'webpackWarnings') {
        return;
      }
      let respData;
      if (typeof e.data === 'string') {
        try {
          respData = JSON.parse(e.data);
        } catch (error) {
          // console.log('ERROR parsing JSON', error)
        }
      } else {
        respData = e.data;
      }
      handleMessages(respData);
    },
    [handleMessages],
  );

  useEffect(() => {
    window.addEventListener('message', respMsg);
    return () => window.removeEventListener('message', respMsg);
  }, [respMsg]);

  return {
    handleVaultError,
    clearErrors,
    errors,
    handleFormSubmit,
    loading,
  };
}

export default useMonerisForm;
